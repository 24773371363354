import { useEffect, useRef, useState } from "react";

import { toHalfWidth } from "henkaku";
import { Transition } from "react-transition-group";
import classes from "./MNPInput.module.css";

function MNPInput(props) {
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [moveLabel, setMoveLabel] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [inputIsVerified, setInputIsVerified] = useState(false);

  const errorTextRef = useRef();
  const instructionTextRef = useRef();

  const verifyAndSubmitInput = (e) => {
    setShowInstruction(false);
    let enteredData = e.target.value.trim();
    const inputName = props.name;
    if (!enteredData) {
      setMoveLabel(false);
      setHasError(true);
      setErrorText("この項目は必須です");
      props.onEmptyInput(inputName);
    } else if (enteredData && props.isHalfWidth) {
      setMoveLabel(true);
      const halfWidthInput = toHalfWidth(enteredData);
      props.submitInput(
        inputName,
        halfWidthInput,
        setHasError,
        setErrorText,
        setInputIsVerified
      );
    }
  };
  const resetErrors = () => {
    setHasError(false);
    setErrorText(null);
    setShowInstruction(true);
  };
  useEffect(() => {
    if (props.alreadySelectedValue) {
      const currentElement = document.getElementsByName(`${props.name}`)[0];
      currentElement.value = props.alreadySelectedValue;
      setMoveLabel(true);
      setInputIsVerified(true);
    } else {
      return;
    }
  }, []);
  return (
    <div className={classes.MNPInputContainer}>
      <label className={classes.inputLabel}>{props.inputLabel}</label>
      <div className={classes.inputAndLabelWrapper}>
        <input
          type={props.type ? props.type : "text"}
          className={`${classes.inputElement} ${
            hasError ? classes.error : inputIsVerified ? classes.verified : null
          }`}
          placeholder={props.placeholderText}
          onBlur={verifyAndSubmitInput}
          onClick={resetErrors}
          autoComplete="new-password"
          name={props.name}
        />
        <p
          className={`${classes.moveableLabel} ${
            moveLabel ? classes.movelabel : null
          } ${
            hasError
              ? classes.labelError
              : inputIsVerified
              ? classes.labelVerified
              : null
          }`}
        >
          {props.label}
        </p>
      </div>
      <div className={classes.errorTextContainer}>
        <Transition
          nodeRef={instructionTextRef}
          in={showInstruction}
          unmountOnExit
          timeout={10}
        >
          {(state) => (
            <p
              ref={instructionTextRef}
              className={`${classes.instructionText} ${
                state === "entering"
                  ? classes.showInstructionText
                  : state === "exiting"
                  ? classes.hideInstructionText
                  : null
              }`}
            >
              {props.entryInstruction}
            </p>
          )}
        </Transition>

        <Transition
          nodeRef={errorTextRef}
          in={hasError}
          unmountOnExit
          timeout={10}
        >
          {(state) => (
            <p
              ref={errorTextRef}
              className={`${classes.errorText} ${
                state === "entering"
                  ? classes.showErrorText
                  : state === "exiting"
                  ? classes.hideErrorText
                  : null
              }`}
            >
              {errorText}
            </p>
          )}
        </Transition>
      </div>
    </div>
  );
}
export default MNPInput;
