import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";

function useSubmitMNPdata() {
  const dispatch = useDispatch();

  return function (
    inputElementName,
    halfWidthInput,
    setHasError,
    setErrorText,
    setInputIsVerified
  ) {
    if (inputElementName === "OTA_ICCD") {
      let regex = /^[A-Z]{2}[A-Z0-9]{1}\d{12}$/g;
      if (regex.test(halfWidthInput)) {
        setInputIsVerified(true);
        setHasError(false);
        setErrorText(null);
        dispatch(orderActions.setMNPOTAICCD(halfWidthInput));
      } else {
        setHasError(true);
        setErrorText("OTAを正しい形式で入力してください。");
        setInputIsVerified(false);
        dispatch(orderActions.setMNPOTAICCD(null));
      }
    } else if (inputElementName === "OTA") {
      let regex = /^\d{11}$/g;
      if (regex.test(halfWidthInput)) {
        setInputIsVerified(true);
        setHasError(false);
        setErrorText(null);
        dispatch(orderActions.setMNPOTA(halfWidthInput));
      } else {
        setHasError(true);
        setErrorText("MNP予約番号を正しい形式で入力してください。");
        setInputIsVerified(false);
        dispatch(orderActions.setMNPOTA(null));
      }
    } else if (inputElementName === "reservationNumber") {
      let regex = /^\d{10}$/g;
      if (regex.test(halfWidthInput)) {
        setInputIsVerified(true);
        setHasError(false);
        setErrorText(null);
        dispatch(orderActions.setMNPReservationNumber(halfWidthInput));
      } else {
        setHasError(true);
        setErrorText("MNP予約番号を正しい形式で入力してください。");
        setInputIsVerified(false);
        dispatch(orderActions.setMNPReservationNumber(null));
      }
    } else {
      let regex = /^\d{11}$/g;
      if (regex.test(halfWidthInput)) {
        setInputIsVerified(true);
        setHasError(false);
        setErrorText(null);
        dispatch(orderActions.setMNP_phoneNumber(halfWidthInput));
      } else {
        setHasError(true);
        setErrorText("電話番号を正しい形式で入力してください。");
        setInputIsVerified(false);
        dispatch(orderActions.setMNP_phoneNumber(null));
      }
    }
  };
}
export default useSubmitMNPdata;
