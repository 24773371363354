import classes from "./mailnoticebox.module.css";

function MailApplicationNoticeBox(props) {
  return (
    <div className={classes.mainNoticeBox}>
      <div className={classes.noticeBox}>
        <h1 className={classes.mainModalHeading}>
          郵送(WEB完結)
          <span className={classes.modalConditionalBreakpoint}>
            お申込みの際のご注意
          </span>
        </h1>
        <p className={classes.mainModalSubHeading}>
          来店されずにお申込みのお客様は{" "}
          <span className={classes.modalConditionalBreakpoint}>
            以下のご確認をお願いします。
          </span>
        </p>
        <div className={classes.numberedStepsContainer}>
          <h3 className={classes.numberedStepsHeading}>
            郵送(WEB完結)は、{" "}
            <span className={classes.modalConditionalBreakpoint}>
              お届けまでに数日かかります。
            </span>
          </h3>
          <ol className={classes.modalRegSteps}>
            <li>WEB申込み</li>
            <li>本人確認書類の確認作業</li>
            <li>
              WEB口座登録の確認(不備がありますと、再度お申し込みが必要です)
            </li>
            <li>初期費用のお振込みのご案内</li>
            <li>
              ご入金確認後、SIMカードの発送となります
              <br />
              (土・日・祝日の発送業務は行っておりません)
            </li>
          </ol>
          <p className={classes.centerText}>
            ご来店でお申し込みなら、即日開通、お渡しが可能です!
          </p>
        </div>

        <button className={classes.modalCloseButton} onClick={props.closed}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="20px"
            height="20px"
            fill="white"
          >
            {" "}
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
          </svg>
        </button>

        <div className={classes.modalPicSteps}>
          <div>
            <p className={classes.modalPicStepHeading}>
              スマートフォンは
              <br /> 付属しません!
            </p>
            <img
              src="https://sky7mobile-shinplan.com/wp-content/themes/sky7mobile/assets/img/store/include_box_1.svg"
              alt="include box"
              className={classes.picStepImage}
            />
            <p className={classes.modalPicStepIntro}>
              原則SIMカード(回線)のご提供です。
            </p>
          </div>
          <div>
            <p className={classes.modalPicStepHeading}>
              WEBでの口座登録が
              <br /> 必須となります!
            </p>
            <img
              src="https://sky7mobile-shinplan.com/wp-content/themes/sky7mobile/assets/img/store/banking_progress.svg"
              alt="include box"
              className={classes.picStepImage}
            />
            <p className={classes.modalPicStepIntro}>
              お申込み終盤にて <br />
              WEB口座登録へ必ずお進みください。
            </p>
            <p>
              {" "}
              <span>WEB口座登録が必須となります。</span>
              <br />
              WEB口座登録が出来ない場合は、
              <br />
              <span>お申込み完了となりません。</span>
            </p>
          </div>
          <div>
            <p className={classes.modalPicStepHeading}>
              初期費用をお振込み後に <br />
              SIMカード発送となります!
            </p>
            <img
              src="https://sky7mobile-shinplan.com/wp-content/themes/sky7mobile/assets/img/store/atm_image.svg"
              alt="include box"
              className={classes.picStepImage}
            />
            <p className={classes.modalPicStepIntro}>
              不備なくお申込み完了となりましたら、
              当社より初期費用及びお振込み先のご案内メール
              を送信させていただきます。
            </p>
            <p>
              ※初期費用のお振込みが確認できましたら、
              身分証明書記載の住所地へ【転送不可】で 発送いたします。
              <br />
              ※初期費用の目安は <a href="/plan">こちら</a> をご確認下さい。{" "}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.actionButtonsContainer}>
        <button
          onClick={() => {
            props.switchToStore();
          }}
        >
          {" "}
          店頭でお渡し
        </button>
        <button onClick={props.closed}> 郵送でお届け</button>
      </div>
    </div>
  );
}

export default MailApplicationNoticeBox;
