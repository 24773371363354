import classes from "./detailConfirmationStep.module.css";
import { useSelector } from "react-redux";

function DetailPlanSection(props) {
  const plan = useSelector((state) => state.order.contractPlan);

  const packetSize = useSelector((state) => state.order.planDataSize);

  const voiceMail = useSelector((state) => state.order.answeringMachine);
  const catchPhone = useSelector((state) => state.order.catchPhone);
  const addPacket = useSelector((state) => state.order.additionalPacket);
  const selectedDate = useSelector((state) => state.order.MNPExpDate);
  const iccidNumber = useSelector((state) => state.order.MNPOTAICCD);
  const handoverNumber = useSelector((state) => state.order.MNP_phoneNumber);
  const temporaryNumber = useSelector((state) => state.order.MNPOTA);
  const deliveryType = useSelector((state) => state.order.mainApplicationType);
  const deliveryMethod = useSelector((state) => state.order.deliveryMethod);
  const mnpReservedNumber = useSelector(
    (state) => state.order.MNPReservationNumber
  );

  const editDetailPlan = () => {
    props.setStep(1);
  };
  return (
    <div>
      <p className={classes.detailSection}>
        <span>ご契約プラン</span>
        <span className={classes.editButtonContainer} onClick={editDetailPlan}>
          <img src="/images/editButton.svg" alt="edit button" />
        </span>
      </p>
      <dl className={classes.termDefContainer}>
        <dt>契約プラン:</dt>
        <dd>{plan === "ポケットWifi2" ? "スカイセブンのWI-FI" : plan}</dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>パケット容量:</dt>
        <dd>{packetSize}GB</dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>留守番電話:</dt>
        <dd>{voiceMail ? "契約する" : "契約しない"}</dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>キャッチホン:</dt>
        <dd>{catchPhone ? "契約する" : "契約しない"}</dd>
      </dl>
      {/* <dl className={classes.termDefContainer}>
        <dt>パケット追加（前入金制）:</dt>
        <dd>{addPacket ? "契約する" : "契約しない"}</dd>
      </dl> */}
      {/* //mnp data */}
      {/**/}
      {(deliveryType === "他社からの乗り換え(MNP)" ||
        deliveryType === "新プラン") && (
        <div>
          {deliveryMethod === "store" && (
            <dl className={classes.termDefContainer}>
              <dt>OTA 製造番号:</dt>
              <dd>{iccidNumber ? iccidNumber : ""}</dd>
            </dl>
          )}

          {deliveryMethod === "store" && (
            <dl className={classes.termDefContainer}>
              <dt>OTA 仮電話番号:</dt>
              <dd>{temporaryNumber}</dd>
            </dl>
          )}
          <dl className={classes.termDefContainer}>
            <dt>MNP電話番号(引継ぎ電話番号） :</dt>
            <dd>{handoverNumber}</dd>
          </dl>
          <dl className={classes.termDefContainer}>
            <dt>MNP予約番号:</dt>
            <dd>{mnpReservedNumber}</dd>
          </dl>
          <dl className={classes.termDefContainer}>
            <dt>予約の有効期限）:</dt>
            <dd>{selectedDate}</dd>
          </dl>
        </div>
      )}
    </div>
  );
}
export default DetailPlanSection;
