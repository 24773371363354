import Ripples from "react-ripples";
import classes from "./progressButton.module.css";

function progressButton(props) {
  return (
    <div className={classes.buttonContainer}>
      <Ripples color="rgba(255, 255, 255, 0.5)" during={1200}>
        <button
          className={classes.progressButton}
          onClick={props.onStepCompleted}
          disabled={props.isDisabled}
        >
          {props.buttonText}
        </button>
      </Ripples>
    </div>
  );
}
export default progressButton;
