import { useRef, useEffect } from "react";

const ClickAwayListener = ({ children, onClickAway }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickAway();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClickAway]);
  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickAwayListener;
