import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import classes from "./RootLayout.module.css";
import ScrollToTop from "./ScrollToTop";

export default function RootLayout() {
  return (
    <div className={classes.orderPageContainer}>
      <ScrollToTop />
      <Header />
      <main className={classes.orderPageMainContent}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
