import classes from "./routeError.module.css";

export default function RouteError() {
  return (
    <div className={classes.contentContainer}>
      <h2 className={classes.errorHeading}>404 - Not Found</h2>
      <p className={classes.errorText}>お探しの店舗が見つかりませんでした。</p>
    </div>
  );
}
