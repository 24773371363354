import { useEffect, useRef } from "react";

import { createPortal } from "react-dom";
import classes from "./modal.module.css";

function Modal({ children, open, onChange }) {
  const modalDialog = useRef();

  useEffect(() => {
    const myModal = modalDialog.current;
    if (open) {
      myModal.showModal();
    }
    return () => myModal.close();
  }, [open]);

  return createPortal(
    <dialog
      ref={modalDialog}
      className={classes.modalContainer}
      onClose={() => onChange()}
    >
      {children}
    </dialog>,
    document.getElementById("modal")
  );
}
export default Modal;
