import { useSelector } from "react-redux";
import classes from "./detailConfirmationStep.module.css";

function CustomerInformationSection(props) {
  const custFirstName = useSelector((state) => state.order.firstName);
  const custLastName = useSelector((state) => state.order.lastName);
  const custFirstNameKana = useSelector((state) => state.order.firstNameKana);
  const custLastNameKana = useSelector((state) => state.order.lastNameKana);
  const custB_Day = useSelector((state) => state.order.birthDay);
  const custGender = useSelector((state) => state.order.gender);
  const custEmail = useSelector((state) => state.order.emailAddress);
  const postalCode = useSelector((state) => state.order.postalCode);
  const prefecture = useSelector((state) => state.order.prefecture);
  const cityTownVillage = useSelector((state) => state.order.cityTownVillage);
  const streetAddress = useSelector((state) => state.order.streetAddress);
  const phoneNumber = useSelector((state) => state.order.tel2);
  const myBuildingInfo = useSelector((state) => state.order.buildingDetails);

  const editDetailPlan = () => {
    props.setStep(2);
  };

  return (
    <div>
      <p className={classes.detailSection}>
        <span>お客様の情報</span>
        <span className={classes.editButtonContainer} onClick={editDetailPlan}>
          <img src="/images/editButton.svg" alt="edit button" />
        </span>
      </p>
      <dl className={classes.termDefContainer}>
        <dt>お名前(フリガナ):</dt>
        <dd>
          {custLastName}
          {custFirstName}({custLastNameKana}
          {custFirstNameKana})
        </dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>住所:</dt>
        <dd>
          〒 {postalCode}
          {prefecture}
          {cityTownVillage}
          {streetAddress}
          {myBuildingInfo ? myBuildingInfo : ""}
        </dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>生年月日:</dt>
        <dd>{custB_Day}</dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>性別:</dt>
        <dd>{custGender}</dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>電話番号:</dt>
        <dd>{phoneNumber ? phoneNumber : "記入されていません"}</dd>
      </dl>
      <dl className={classes.termDefContainer}>
        <dt>メールアドレス:</dt>
        <dd>{custEmail}</dd>
      </dl>
    </div>
  );
}
export default CustomerInformationSection;
