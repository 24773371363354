import { useState } from "react";
import ApplicationStepsIndicatator from "./ApplicationStepsIndicatator";
import DocumentSubmission from "./DocumentSubmission/DocumentSubmissionStep";
import classes from "./applicationProcess.module.css";
import ApplicationComplete from "./completeApplication/ApplicationComplete";
import ConfirmBeforeApplication from "./confirmBeforeApplication/ConfirmBeforeApplication";
import CurrentSelections from "./currentSelection/CurrentSelections";
import CustomerDetailsForm from "./customerDetailsStep/CustomerDetailsForm";
import DetailConfirmationStep from "./detailConfirmationStep/DetailConfirmationStep";
import InitialFeeReceiptPage from "./feeReceipt/InitialFeeReceiptPage";
import OrderPlanSelection from "./orderPlanSelection/OrderPlanSelection";
import SettingUpAccount from "./setUpAccount/SettingUpAccount";
import { AnimatePresence } from "framer-motion";

function ApplicationProcess(props) {
  const [iDAndDocuments, setIdsAndDocuments] = useState({
    id_upload_1: null,
    id_upload_2: null,
    support_doc_1: null,
    support_doc_2: null,
    support_doc_3: null,
  });
  const [initialFeeRecDoc, setInitialFeeRecDoc] = useState(null);
  const [contractDoc, setContractDoc] = useState(null);

  const establishApplicationStage = props.applicationData.setApplicationStage;
  const applicationStage = props.applicationData.applicationStage;

  const handleIdUpload = (fileId, file) => {
    if (fileId === "id_upload_1") {
      setIdsAndDocuments({ ...iDAndDocuments, id_upload_1: file });
    } else if (fileId === "id_upload_2") {
      setIdsAndDocuments({ ...iDAndDocuments, id_upload_2: file });
    } else if (fileId === "support_doc_1") {
      setIdsAndDocuments({ ...iDAndDocuments, support_doc_1: file });
    } else if (fileId === "support_doc_2") {
      setIdsAndDocuments({ ...iDAndDocuments, support_doc_2: file });
    } else {
      setIdsAndDocuments({ ...iDAndDocuments, support_doc_3: file });
    }
  };
  const handleInitialFeeUpload = (fileId, file) => {
    setInitialFeeRecDoc(file);
  };
  const handleContractDocumentUpload = (fileId, file) => {
    setContractDoc(file);
  };

  return (
    <div className={classes.applicationProcessContainer}>
      <ApplicationStepsIndicatator applicationStage={applicationStage} />
      <AnimatePresence>
        <div className={classes.applicationContainer}>
          {applicationStage === 0 && (
            <ConfirmBeforeApplication onComplete={establishApplicationStage} />
          )}
          {applicationStage === 1 && (
            <OrderPlanSelection onComplete={establishApplicationStage} />
          )}
          {applicationStage === 2 && (
            <CustomerDetailsForm onComplete={establishApplicationStage} />
          )}
          {applicationStage === 3 && (
            <DocumentSubmission
              onComplete={establishApplicationStage}
              onUploaded={handleIdUpload}
            />
          )}
          {applicationStage === 4 && (
            <InitialFeeReceiptPage
              onComplete={establishApplicationStage}
              onUploaded={handleInitialFeeUpload}
              onUploadedContractDocument={handleContractDocumentUpload}
            />
          )}
          {applicationStage === 5 && (
            <DetailConfirmationStep
              onComplete={establishApplicationStage}
              allDocs={iDAndDocuments}
              initialFeeRecFile={initialFeeRecDoc}
              contractDoc={contractDoc}
            />
          )}
          {applicationStage === 6 && (
            <SettingUpAccount onComplete={establishApplicationStage} />
          )}
          {applicationStage === 7 && (
            <ApplicationComplete onComplete={establishApplicationStage} />
          )}
        </div>
      </AnimatePresence>

      {applicationStage > 0 && applicationStage < 4 && <CurrentSelections />}
    </div>
  );
}
export default ApplicationProcess;
