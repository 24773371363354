import { AnimatePresence, motion } from "framer-motion";
import classes from "./applicationStepsIndicator.module.css";

function ApplicationStepsIndicatator(props) {
  return (
    <AnimatePresence>
      <motion.div
        className={classes.stepsContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage > 0 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 0 ? <span>&#10003;</span> : 0}
        </span>
        ー
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage >= 1 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 1 ? <span>&#10003;</span> : 1}
        </span>
        ー
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage >= 2 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 2 ? <span>&#10003;</span> : 2}
        </span>
        ー
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage >= 3 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 3 ? <span>&#10003;</span> : 3}
        </span>
        ー
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage >= 4 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 4 ? <span>&#10003;</span> : 4}
        </span>
        ー
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage >= 5 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 5 ? <span>&#10003;</span> : 5}
        </span>
        ー
        <span
          className={`${classes.progressIndicator} ${
            props.applicationStage >= 6 ? classes.workedOn : null
          }`}
        >
          {props.applicationStage > 6 ? <span>&#10003;</span> : 6}
        </span>
      </motion.div>
    </AnimatePresence>
  );
}
export default ApplicationStepsIndicatator;
