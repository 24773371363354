import classes from "./detailTermDefinition.module.css";

function DetailTermDefinition(props) {
  return (
    <div className={classes.termAndDefinition}>
      <dl className={classes.termDefContainer}>
        <dt>{props.term}</dt>
        <dd>{props.termDefinition}</dd>
      </dl>
    </div>
  );
}
export default DetailTermDefinition;
