import { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./settingUpAccount.module.css";

function AccountCard(props) {
  const [viewAccountNumber, setViewAccountNumber] = useState(false);
  const applicationAccountNumber = useSelector(
    (state) => state.order.applicationAccountNumber
  );
  const showAccountNumber = () => {
    setViewAccountNumber(true);
  };
  return (
    <div>
      <div className={classes.settingUpAccountCard}>
        <h3>店頭で口座登録を始める</h3>
        <div className={classes.infoBox}>
          <div className={classes.inStructionContainer}>
            <p>店舗スタッフが専用端末で口座登録をいたします。</p>
            <p>キャッシュカードをご用意し、店舗スタッフにお渡しください。</p>
          </div>
          {!viewAccountNumber && (
            <div className={classes.buttonWrap}>
              <button
                className={classes.accountSetUpButton}
                onClick={showAccountNumber}
              >
                店頭で口出登録を始める
              </button>
            </div>
          )}
        </div>

        {viewAccountNumber && (
          <div>
            <p className={classes.accountInstruction}>
              以下のコードを専用端木に入力の上、手続きを開始してください。
            </p>
            <p className={classes.accountNumber}>{applicationAccountNumber}</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default AccountCard;
