import classes from "./subscriptionApplication.module.css";

function SubscriptionApplication({ viewControl, storeInfo }) {
  const storeCode = storeInfo.submittedParam.storeNumber;
  const storeData = storeInfo.storeData[0];

  const startApplication = () => {
    viewControl();
  };

  return (
    <div className={classes.subscriptionApplicationContainer}>
      <div className={classes.subApplicationContainer}>
        <p className={classes.subscriptionApplicationHeading}>
          スカイセブンモバイル　新プラン申込
        </p>
        <span className={classes.storeName}>
          店舗名:{storeData.shopName ? storeData.shopName : ""} (
          {storeCode ? storeCode : ""})
        </span>
        <p className={classes.prepInstruction}>
          申込み開始前に、身分証明書と銀行のキャッシュカードをご用意ください。
        </p>
        <div className={classes.buttonContainer}>
          <button className={classes.appButton} onClick={startApplication}>
            申し込みを開始する
          </button>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionApplication;
