import Ripple from "react-ripples";
import classes from "./fileUploadButton.module.css";

function FileUploadButton(props) {
  const handleNextAct = () => {
    props.doNextAction();
  };
  return (
    <div className={classes.fileUpButtonContainer}>
      <Ripple>
        <button
          className={classes.fileUpButton}
          disabled={props.isDisabled}
          onClick={handleNextAct}
        >
          {props.buttonText}
        </button>
      </Ripple>
    </div>
  );
}
export default FileUploadButton;
