import Ripples from "react-ripples";
import classes from "./progressButton.module.css";

function ProgressToCustomerInputButton(props) {
  return (
    <div className={classes.buttonContainer}>
      <Ripples color="rgba(255, 255, 255, 0.5)" during={1200}>
        <button
          className={classes.progressButton}
          onClick={props.onStepCompleted}
          disabled={props.isDisabled}
        >
          約款・重要事項説明書を確認、
          <br />
          同意の上お客様情報入力に進む
        </button>
      </Ripples>
    </div>
  );
}
export default ProgressToCustomerInputButton;
