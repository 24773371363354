import { useSelector } from "react-redux";
import setDisplayedUploads from "../functions/setDisplayedUploads";
import classes from "./documentSubmissionStep.module.css";

function DocumentChecklist(props) {
  const idFront = useSelector((state) => state.order.idFront);
  const idBack = useSelector((state) => state.order.idBack);
  const support_doc_1 = useSelector((state) => state.order.document_1);
  const idType = useSelector((state) => state.order.idType);

  const displayBack = setDisplayedUploads(idType);

  return (
    <div className={classes.warningContainer}>
      <p>以下の書類を添付してください。</p>
      <ul className={classes.warningsListContainer}>
        {!idFront && <li>{props.currentId}（表面）</li>}
        {!idBack && displayBack && <li>{props.currentId}（裏面）</li>}
        {(idType === "住民基本台帳カード" && !support_doc_1) ||
          (idType === "健康保険証" && !support_doc_1 && <li>補助書類１</li>)}
      </ul>
    </div>
  );
}
export default DocumentChecklist;
