import classes from "./footer.module.css";

function Footer() {
  return (
    <div className={classes.Footer}>
      <p className={classes.copyRightText}>
        Copyright © 2023 スカイセブンモバイル All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
