import { useSelector } from "react-redux";
import classes from "./detailConfirmationStep.module.css";

function IdDocumentConfirmation(props) {
  const submittedIdType = useSelector((state) => state.order.idType);
  const editDetailPlan = () => {
    props.setStep(3);
  };
  return (
    <div>
      <p className={classes.detailSection}>
        <span>本人確認書類</span>
        <span className={classes.editButtonContainer} onClick={editDetailPlan}>
          <img src="/images/editButton.svg" alt="edit button" />
        </span>
      </p>
      <dl className={classes.termDefContainer}>
        <dt>種類:</dt>
        <dd>{submittedIdType}</dd>
      </dl>
    </div>
  );
}
export default IdDocumentConfirmation;
