import { useDispatch } from "react-redux";

import MNPSelection from "./MNPSelection";
import classes from "./MNPSelections.module.css";
import { orderActions } from "../../store/orderSlice";
// import { useEffect } from "react";

function MNPSelections() {
  const dispatch = useDispatch();
  // const submitSelectedChoice = (inputValue) => {
  //   if (inputValue === "現在") {
  //     dispatch(orderActions.setApplicationType(inputValue));
  //     dispatch(orderActions.setTransferAndSubscriberName("名同じ"));
  //   } else {
  //     dispatch(orderActions.setApplicationType(inputValue));
  //     dispatch(orderActions.setTransferAndSubscriberName(null));
  //   }
  // };
  const submitTransferAndSubscriberName = (inputValue) => {
    dispatch(orderActions.setTransferAndSubscriberName(inputValue));
  };
  // const applicationType = useSelector((state) => state.order.applicationType);
  // const deliveryMethod = useSelector((state) => state.order.deliveryMethod);
  // const SIMSubscriptionType = useSelector(
  //   (state) => state.order.mainApplicationType
  // );

  // const isNotAllowed =
  //   SIMSubscriptionType === "新プラン" ||
  //   (SIMSubscriptionType === "他社からの乗り換え(MNP)" &&
  //     deliveryMethod === "store");

  // useEffect(() => {
  //   if (isNotAllowed) {
  //     dispatch(orderActions.setApplicationType("現在"));
  //   } else {
  //     return;
  //   }
  // }, [dispatch, isNotAllowed]);
  return (
    <div className={classes.MNPSelectionsContainer}>
      {/* <div className={classes.MNPOptionContainer}>
        <p className={classes.applicationContainerHeading}>
          携帯電話番号ポータビリティ（MNP）のお申込み
        </p>
        <hr />

          <MNPSelection
            description={"新しい番号を利用する(MNPを申込まない)"}
            value={"新し"}
            name={"applicationType"}
            onChoiceSelected={submitSelectedChoice}
          />
        
     
          <MNPSelection
            description={"現在ご利用中の電話番号を利用する(MNPを申込む)"}
            value={"現在"}
            name={"applicationType"}
            onChoiceSelected={submitSelectedChoice}
          />
        
      </div> */}

      {/* {applicationType === "現在" && ( */}
      <div>
        <p className={classes.applicationContainerHeading}>
          転入元の名義とご契約者様の名義は同じですか？
        </p>

        <span className={classes.description}>
          ※ご契約の名義人が異なる場合は、MNP転入できません。
        </span>
        <hr />
        <MNPSelection
          description={"転入元の名義とご契約者様の名義が同じ"}
          value={"名同じ"}
          name={"subscriberName"}
          onChoiceSelected={submitTransferAndSubscriberName}
        />
        {/* <MNPSelection
            description={"転入元の名義とご契約者様の名義が違う"}
            value={"名違う"}
            name={"subscriberName"}
            onChoiceSelected={submitTransferAndSubscriberName}
          /> */}
      </div>
      {/* )} */}
    </div>
  );
}
export default MNPSelections;
