import Ripples from "react-ripples";
import classes from "./MNPSelection.module.css";
import { useSelector } from "react-redux";

function MNPSelection(props) {
  const myValue = props.value;
  const applicationType = useSelector((state) => state.order.applicationType);
  const subscriberIsSameName = useSelector(
    (state) => state.order.subscriberIsSameName
  );

  const submitApplicationType = (e) => {
    let appType = e.target.value;
    props.onChoiceSelected(appType);
  };

  return (
    <div className={classes.applicationTypeContainer}>
      <div className={classes.applicationTypes}>
        <div className={classes.MNPLabel}>
          <Ripples className={classes.rippleWrapper} during={1500}>
            {" "}
            <label
              className={
                applicationType === myValue || subscriberIsSameName === myValue
                  ? classes.labelFocus
                  : null
              }
            >
              <input
                type="radio"
                name={props.name}
                value={props.value}
                checked={
                  applicationType === myValue ||
                  subscriberIsSameName === myValue
                    ? true
                    : false
                }
                className={classes.deliveryMode}
                onChange={submitApplicationType}
              />
              {props.description}
            </label>
          </Ripples>
        </div>
      </div>
    </div>
  );
}
export default MNPSelection;
