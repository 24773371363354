import { useEffect, useState } from "react";

import ErrorComponent from "../confirmBeforeApplication/ErrorComponent";
import classes from "./dataInput.module.css";

function DataInput(props) {
  const [wasTouched, setWasTouched] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const [hasNoInput, setHasNoInput] = useState(false);

  const verifyEnteredValue = (event) => {
    setHasInput(true);
    props.verifyInput(
      event,
      wasTouched,
      setErrorText,
      setHasError,
      setIsVerified
    );
  };
  const handleBlurVerification = (e) => {
    let enteredText = e.target.value.trim();
    if (enteredText) {
      setHasInput(true);
      setHasNoInput(false);
    } else {
      setErrorText(true);
      setHasError(true);
      setIsVerified(false);
      setErrorText("この項目は必須です");
      setHasNoInput(true);
    }
  };

  const handleClick = () => {
    setWasTouched(true);
    setHasInput(false);
  };

  useEffect(() => {
    if (props.alreadyAvailable) {
      document.getElementsByName(props.name)[0].value = props.alreadyAvailable;
      setHasInput(true);
      setIsVerified(true);
    } else {
      return;
    }
  }, []);

  return (
    <div className={classes.inputContainer}>
      <input
        type={props.type}
        name={props.name}
        inputMode={props.inputMode}
        onChange={verifyEnteredValue}
        maxLength={20}
        onClick={handleClick}
        onBlur={handleBlurVerification}
        autoComplete="new-password"
        className={`${classes.inputElement} ${
          isVerified ? classes.success : hasError ? classes.error : null
        }`}
      />
      <p
        className={`${classes.placeHolder} ${
          hasInput ? classes.keepLabel : classes.dropLabel
        } ${
          hasNoInput || hasError
            ? classes.labelError
            : isVerified
            ? classes.labelSuccess
            : null
        } `}
      >
        {props.placeholder}
      </p>
      {<ErrorComponent errorText={errorText} />}
    </div>
  );
}
export default DataInput;
