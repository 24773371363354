import classes from "./Header.module.css";

export default function Header() {
  return (
    <div className={classes.topBannerContainer}>
      <div className={classes.topBannerImageContainer}>
        <img
          src="/images/logo.webp"
          className={classes.topBannerImage}
          alt="sky_seven mobile logo"
        />
      </div>
    </div>
  );
}
