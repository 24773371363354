import RouteError from "../RouteError";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import classes from "./errorElement.module.css";

export default function errorElement() {
  return (
    <div className={classes.errorElementContainer}>
      <div className={classes.errorComponent}>
        <Header />
        <RouteError />
        <Footer />
      </div>
    </div>
  );
}
