import { useLocation, useParams } from "react-router-dom";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import ProgressButton from "../commonComponents/ProgressButton";
import DetailTermDefinition from "./DetailTermDefinition";
import classes from "./applicationComplete.module.css";

function ApplicationComplete() {
  const params = useParams();
  const location = useLocation();
  const storeUserEmail = useSelector((state) => state.order.emailAddress);
  const applicationAccountNumber = useSelector(
    (state) => state.order.applicationAccountNumber
  );

  const searchParams = new URLSearchParams(location.search);
  const storeId = params.storeNumber;
  const paymentStatus = searchParams.get("status");
  const userEmail = searchParams.get("email");
  const customerCode = searchParams.get("customer_code");

  let arrivedIllegally =
    !paymentStatus &&
    !userEmail &&
    !customerCode &&
    !storeUserEmail &&
    !applicationAccountNumber;

  useEffect(() => {
    if (arrivedIllegally) {
      window.location.href = `/${storeId}`;
    } else {
    }
  }, []);
  const returnToTop = () => {
    window.location.href = `/${storeId}`;
  };
  const resultFailed = paymentStatus === "failure";

  return (
    <div className={classes.ApplicationCompleteStep}>
      {!resultFailed && (
        <h2 className={classes.applicationCompleteStepHeading}>申し込み完了</h2>
      )}
      {resultFailed && (
        <h2 className={classes.applicationCompleteStepHeading}>
          Web口座振替登録失敗
        </h2>
      )}
      <div className={classes.emailNoticeExplanation}>
        {!resultFailed && (
          <p>
            <span>
              申し込み内容の控えを以下のメールアドレスに送信しました。
            </span>

            <span>お手続き完了後メールにてお知らせいたします。</span>
          </p>
        )}
        {paymentStatus && resultFailed && (
          <p>
            <span>口座振替登録に失敗しました。</span>

            <span>以下のメールアドレスに申し込み控えを送信しております。</span>
          </p>
        )}
      </div>
      <div className={classes.appCompleteNotice}>
        <DetailTermDefinition
          term={"メールアドレス: "}
          termDefinition={userEmail ? userEmail : storeUserEmail}
        />
        <DetailTermDefinition
          term={"申し込み番号: "}
          termDefinition={
            customerCode ? customerCode : applicationAccountNumber
          }
        />
      </div>

      {resultFailed && (
        <div className={classes.appFailureNotice}>
          <div>
            <p>申し込み控えとキャッシュカードと暗証番号をご用意の上</p>
            <p>
              最寄りの店舗にご来店いただくか、サポートデスクにご連絡ください。
            </p>
          </div>
          <div className={classes.helpDetails}>
            <h3>スカイセブンモバイル お客様サポート</h3>
            <DetailTermDefinition
              term={"メールアドレス: "}
              termDefinition={"help@sky7mobile.ne.jp"}
            />
            <DetailTermDefinition
              term={"電話番号"}
              termDefinition={"050-5526-1828 (受付時間 10：00～18：00 )"}
            />
          </div>
        </div>
      )}
      <ProgressButton
        buttonText={"トップに戻る"}
        isDisabled={false}
        onStepCompleted={returnToTop}
      />
    </div>
  );
}
export default ApplicationComplete;
