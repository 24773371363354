import { useState } from "react";
import { useSelector } from "react-redux";
import CurrentSelectionDetails from "./CurrentSelectionDetails";
import calculateCurrentSelectionCost from "./calculateCurrentSelectionCost";
import classes from "./currentSelections.module.css";

function CurrentSelections() {
  const [detailsAreViewable, setDetailsAreViewable] = useState(false);
  const contractPlan = useSelector((state) => state.order.contractPlan);
  const planPricing = useSelector((state) => state.order.monthlyFeeWithTax);
  const answeringMachine = useSelector((state) => state.order.answeringMachine);
  const catchPhone = useSelector((state) => state.order.catchPhone);
  const packetSize = useSelector((state) => state.order.planDataSize);

  const pricincingData = calculateCurrentSelectionCost(
    contractPlan,
    planPricing,
    catchPhone,
    answeringMachine,
    packetSize
  );

  const chosenPlan = useSelector((state) => state.order.contractPlan);
  const storeNum = useSelector((state) => state.order.shopCode);

  const viewDetails = () => {
    setDetailsAreViewable(true);
  };
  const closeModal = () => {
    setDetailsAreViewable(false);
  };

  return (
    <div className={classes.currentSelectionsContainer}>
      <ul>
        <li>
          <span className={classes.labelText}> 契約時お支払い額</span>
          <span className={classes.selection}>
            　
            {chosenPlan.includes("ポケットWifi")
              ? pricincingData.totalFeeFrom.toLocaleString("en-US")
              : pricincingData.totalFeeFrom.toLocaleString("en-US")}
            円　~
          </span>
        </li>
        <li>
          <span className={classes.labelText}>毎月の料金(目安)</span>
          <span className={classes.selection}>
            　
            {chosenPlan === "ポケットWifi" || chosenPlan === "ポケットWifi2"
              ? pricincingData.soleMonthlyFee.toLocaleString("en-US")
              : pricincingData.soleMonthlyFee.toLocaleString("en-US")}
            円<span className={classes.perMonth}>/月</span>
          </span>
        </li>
        {storeNum === "00000001" && (
          <li className={classes.viewDetails} onClick={viewDetails}>
            詳細・内訳はこちら
          </li>
        )}
      </ul>

      <CurrentSelectionDetails
        detailResult={pricincingData}
        isOpen={detailsAreViewable}
        onCloseModal={closeModal}
      />
    </div>
  );
}
export default CurrentSelections;
