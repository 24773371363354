import React from "react";
import AgreementPage from "../Agreement/AgreementPage";

function UsageGuidelines() {
  return (
    <div>
      <AgreementPage />
    </div>
  );
}

export default UsageGuidelines;
