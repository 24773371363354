import { useSelector } from "react-redux";
import useSetMNPerrors from "../functions/useSetMNPerrors";
import useSubmitMNPdata from "../functions/useSubmitMNPdata";
import MNPExpDates from "./MNPExpDates";
import classes from "./MNPForm.module.css";
import MNPInput from "./MNPInput";

function MNPForm() {
  const verifyAndSubmitUserInput = useSubmitMNPdata();
  const resetMNPErros = useSetMNPerrors();

  // const selectedPlan = useSelector((state) => state.order.contractPlan);
  const deliveryMethod = useSelector((state) => state.order.deliveryMethod);
  const deliveryType = useSelector((state) => state.order.mainApplicationType);

  const MNPICCD = useSelector((state) => state.order.MNPOTAICCD);
  const alreadySubmittedMNP_OTA = useSelector((state) => state.order.MNPOTA);
  const alreadySubmittedRESNumber = useSelector(
    (state) => state.order.MNPReservationNumber
  );
  const alreadySubmittedMNPPhoneNumber = useSelector(
    (state) => state.order.MNP_phoneNumber
  );

  return (
    <div className={classes.MNPFormWrapper}>
      <p className={classes.required}>MNP転入元の情報を入力してください。</p>
      <div className={classes.MNPFormDetails}>
        {deliveryMethod === "store" &&
          (deliveryType === "新プラン" ||
            deliveryType === "他社からの乗り換え(MNP)") && (
            <MNPInput
              label={"OTA 製造番号"}
              inputLabel={"OTA 製造番号"}
              isHalfWidth
              submitInput={verifyAndSubmitUserInput}
              name={"OTA_ICCD"}
              onEmptyInput={resetMNPErros}
              placeholderText="英字(2文字)と数字(13桁)"
              entryInstruction={"英字(2文字)と数字(13桁)"}
              alreadySelectedValue={MNPICCD}
            />
          )}
        {deliveryMethod === "store" &&
          (deliveryType === "新プラン" ||
            deliveryType === "他社からの乗り換え(MNP)") && (
            <MNPInput
              label={"OTA 仮電話番号"}
              inputLabel={"OTA 仮電話番号"}
              isHalfWidth
              name={"OTA"}
              onEmptyInput={resetMNPErros}
              submitInput={verifyAndSubmitUserInput}
              placeholderText="OTA仮電話番号(11桁)"
              entryInstruction={"OTA仮電話番号(11桁)"}
              alreadySelectedValue={alreadySubmittedMNP_OTA}
            />
          )}
        <MNPInput
          label={"MNP電話番号(引継ぎ電話番号）"}
          inputLabel={"MNP電話番号(引継ぎ電話番号）"}
          isHalfWidth
          onEmptyInput={resetMNPErros}
          submitInput={verifyAndSubmitUserInput}
          name={"MNP_phoneNumber"}
          placeholderText="電話番号(11桁)"
          entryInstruction={"半角数字11桁"}
          alreadySelectedValue={alreadySubmittedMNPPhoneNumber}
        />
        <MNPInput
          label={"MNP予約番号"}
          inputLabel={"MNP予約番号"}
          isHalfWidth
          onEmptyInput={resetMNPErros}
          submitInput={verifyAndSubmitUserInput}
          name={"reservationNumber"}
          placeholderText="数字(10桁)"
          entryInstruction={"半角数字10桁"}
          alreadySelectedValue={alreadySubmittedRESNumber}
        />

        <MNPExpDates />
      </div>
    </div>
  );
}
export default MNPForm;
