import { useSelector } from "react-redux";
import MNPForm from "./MNPForm";
import classes from "./MNPSection.module.css";
import MNPSelections from "./MNPSelections";

// import MNPInfo from "./MNPInfo";

//
function MNPSection() {
  const SIMSubscriptionType = useSelector(
    (state) => state.order.mainApplicationType
  );
  const isAllowed =
    SIMSubscriptionType === "新プラン" ||
    SIMSubscriptionType === "他社からの乗り換え(MNP)";
  return (
    <section className={classes.MNPSection}>
      <div>
        <MNPSelections />
      </div>
      <div>{/* <MNPInfo /> */}</div>
      {isAllowed && (
        <div>
          <MNPForm />
        </div>
      )}
    </section>
  );
}
export default MNPSection;
