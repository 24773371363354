import { toHalfWidth } from "henkaku";
import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";

const useVerifySerialNumber = () => {
  const dispatch = useDispatch();
  return function (
    event,
    wasTouched,
    setErrorText,
    setHasError,
    setIsVerified
  ) {
    let enteredValue = event.target.value.trim();
    setHasError(false);
    setErrorText(null);
    setIsVerified(false);

    let serialRegex = /^[A-Z]{2}\d{13}$/g;

    if (wasTouched && !event.target.value) {
      setHasError(true);
      setErrorText("この項目は必須です");
      dispatch(orderActions.setPhoneAndSNumberValidity("snInvalid"));
      dispatch(orderActions.setSerialNumber(null));
      return false;
    } else if (wasTouched && enteredValue && enteredValue.match(serialRegex)) {
      let halfWidthData = toHalfWidth(enteredValue);
      setHasError(false);
      setErrorText(null);
      setIsVerified(true);
      dispatch(orderActions.setPhoneAndSNumberValidity("snValid"));
      dispatch(orderActions.setSerialNumber(halfWidthData));
      return true;
    } else {
      setHasError(true);
      setErrorText("ＳＩＭカード製造番号の形式が間違っています");
      setIsVerified(false);
      dispatch(orderActions.setPhoneAndSNumberValidity("snInvalid"));
      dispatch(orderActions.setSerialNumber(null));
      return false;
    }
  };
};
export default useVerifySerialNumber;
