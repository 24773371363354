let defaultState = {
  deliveryMethod: "store",
  contractShopStaff: null,
  contractCost: 0,
  monthlyFeeWithTax: 3256,
  shopID: null,
  shopCode: 0,
  plan: null,
  planDataSize: 1,
  contractPlan: "音声プラン",
  CBAPhoneIsValid: false,
  CBASerialNumberIsValid: false,
  //plans Page Data
  buttonIsNotActive: true,
  planOption: null,
  planOptionPrice: 0,
  answeringMachine: false,
  additionalPacket: false,
  catchPhone: false,
  termsRead: false,
  mainApplicationType: "新規申込",
  applicationType: "新し",
  subscriberIsSameName: "名違う",
  MNPOTAICCD: null,
  MNPOTA: null,
  MNP_phoneNumber: null,
  MNPReservationNumber: null,
  MNPExpDate: null,
  PocketWifiOn: true,
  monthlyDataOn: false,
  //customerData
  firstName: "",
  lastName: "",
  // firstName: "John",
  // lastName: "Paintsil",
  firstNameKana: "",
  lastNameKana: "",
  // firstNameKana: "ﾌﾗﾝｸ",
  // lastNameKana: "ﾌﾗ",
  birthYear: null,
  birthMonth: null,
  // birthDaySubmitted: null,
  birthDay: null,
  storeApplicationNumber: null,
  emailAddress: "",
  gender: "男性",
  customerAge: "null",
  phoneNumber: null,
  serialNumber: null,
  Imei: null,
  // customer Address
  postalCode: 0,
  cityTownVillage: "",
  streetAddress: "",
  prefecture: "",
  // prefecture: "沖縄県",
  buildingDetails: "",
  prefectureIsAvailabled: false,
  cityTownVillageAvailable: false,
  postalCodeAvailable: false,
  streetAddressAvailable: false,
  //document Submission'
  idType: "運転免許証",
  idFront: null,
  idBack: null,
  document_1: null,
  document_2: null,
  document_3: null,
  initialFeeReceipt: null,
  contractDocument: "",
  //returned data
  applicationAccountNumber: 0,
  //other data
  contractStartAt: null,
  contractEndAt: null,
  temporaryNumber: null,
  handoverNumber: null,
  MNPResNumberEffDate: null,
  simType: null,
  contractEndDate: null,
  tel2: null,
  emailAddress2: "",
  contractState: "0",
};
export default defaultState;
