import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { orderActions } from "../../store/orderSlice";
import ProgressButton from "../commonComponents/ProgressButton";
import ApplicationType from "./ApplicationType";
import classes from "./confirmBeforeApplicationForm.module.css";
import Modal from "../ui/Modal";
import MailApplicationNoticeBox from "./MailApplicationNoticeBox";

function ConfirmBeforeApplicationForm({ onComplete }) {
  const [deliveryType, setDeliveryType] = useState("store");
  const [isNotMNP, setIsNotMNP] = useState(false);
  const [detailsAreViewable, setDetailsAreViewable] = useState(false);

  const closeModal = () => {
    setDetailsAreViewable(false);
  };

  const appType = useSelector((state) => state.order.mainApplicationType);
  // const headOffice =
  //   useSelector((state) => state.order.shopCode) === "00000001";

  const dispatch = useDispatch();

  const setDeliveryMethod = (event) => {
    dispatch(orderActions.setDeliveryMethod(event.target.value));
    setDeliveryType(event.target.value);
  };
  const setDeliveryToStore = () => {
    dispatch(orderActions.setDeliveryMethod("store"));
    setDeliveryType("store");
    closeModal();
  };

  const setApplicationProcessStage = () => {
    onComplete(1);
  };

  useEffect(() => {
    if (appType !== "他社からの乗り換え(MNP)") {
      setIsNotMNP(true);
    } else {
      setIsNotMNP(false);
      return;
    }
  }, [appType]);

  useEffect(() => {
    if (deliveryType === "mail") {
      setDetailsAreViewable(true);
    }
  }, [deliveryType]);

  return (
    <div className={classes.applicationGeneralContainer}>
      <Modal open={detailsAreViewable} onChange={closeModal}>
        <MailApplicationNoticeBox
          closed={closeModal}
          switchToStore={setDeliveryToStore}
        />
      </Modal>
      <div className={classes.applicationContainer}>
        <div>
          <ApplicationType />
        </div>
        <div>
          <p className={classes.applicationContainerHeading}>
            ＳＩＭカードのお渡し
          </p>
          <hr />
          <div className={classes.applicationDeliveryMode}>
            <label htmlFor="store">
              <input
                type="radio"
                name="deliveryMode"
                value="store"
                id="store"
                checked={deliveryType === "store" ? true : false}
                className={classes.deliveryMode}
                onChange={setDeliveryMethod}
              />
              店頭でお渡し
            </label>
            {/* {(isNotMNP || headOffice) && ( */}
            <label htmlFor="mail">
              <input
                type="radio"
                name="deliveryMode"
                value="mail"
                id="mail"
                checked={deliveryType === "mail" ? true : false}
                className={classes.deliveryMode}
                onChange={setDeliveryMethod}
              />
              郵送でお届け
            </label>
            {/* )} */}
          </div>
        </div>

        <div className={classes.orderDeliveryMethodBtnContainer}>
          <ProgressButton
            isDisabled={false}
            onStepCompleted={setApplicationProcessStage}
            buttonText={"申し込みに進む"}
          />
        </div>
      </div>
    </div>
  );
}
export default ConfirmBeforeApplicationForm;
