import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./agreementlinks.module.css";
import { orderActions } from "../store/orderSlice";
import { useEffect, useState } from "react";

function AgreementLinks(props) {
  const [agreementStatus, setAgreementStatus] = useState({
    termsandcond: false,
    sim: false,
    wifi: false,
  });
  const dispatch = useDispatch();

  const agreementLinkRead = (number) => {
    switch (number) {
      case 1:
        setAgreementStatus((prevStatus) => {
          return {
            ...prevStatus,
            termsandcond: true,
          };
        });
        break;
      case 2:
        setAgreementStatus((prevStatus) => {
          return {
            ...prevStatus,
            sim: true,
          };
        });
        break;
      default:
        setAgreementStatus((prevStatus) => {
          return {
            ...prevStatus,
            wifi: true,
          };
        });
        break;
    }
  };

  useEffect(() => {
    if (agreementStatus.termsandcond && agreementStatus.sim) {
      dispatch(orderActions.termsRead());
    } else if (agreementStatus.termsandcond && agreementStatus.wifi) {
      dispatch(orderActions.termsRead());
    }
  }, [agreementStatus, dispatch]);

  return (
    <div className={classes.agreementlinkscontainer}>
      <div>
        <input
          type="checkbox"
          checked={agreementStatus.termsandcond}
          readOnly
        />
        <Link
          to={`https://sky7mobile-shinplan.com/wp-content/themes/sky7mobile/assets/legal/TermsAndConditions.pdf`}
          target="_blank"
          rel="noreferrer"
          onClick={() => agreementLinkRead(1)}
        >
          約款についてご確認ください。
        </Link>
      </div>

      {(props.selectedPlan === "データ＋ＳＭＳプラン" ||
        props.selectedPlan === "音声プラン") && (
        <div>
          <input type="checkbox" checked={agreementStatus.sim} readOnly />{" "}
          <Link
            to={`https://sky7mobile-shinplan.com/wp-content/themes/sky7mobile/assets/legal/simlegal.pdf`}
            target="_blank"
            rel="noreferrer"
            onClick={() => agreementLinkRead(2)}
          >
            重要事項説明書（SIM）についてご確認ください。
          </Link>
        </div>
      )}
      {(props.selectedPlan === "ポケットWifi" ||
        props.selectedPlan === "ポケットWifi2") && (
        <div>
          <input type="checkbox" checked={agreementStatus.wifi} readOnly />
          <Link
            to={`https://sky7mobile-shinplan.com/wp-content/themes/sky7mobile/assets/legal/wifilegal.pdf`}
            target="_blank"
            rel="noreferrer"
            onClick={() => agreementLinkRead(3)}
          >
            重要事項説明書（WI-FI）についてご確認ください。
          </Link>
        </div>
      )}
    </div>
  );
}

export default AgreementLinks;
