export default function getExpirationDates(
  numberOfDaysInList,
  daysFromStartingDay
) {
  const today = new Date();
  const startingDay = new Date(
    today.getTime() + daysFromStartingDay * 24 * 60 * 60 * 1000
  );
  // const fiveDaysFromNow = new Date(
  //   startingDay.getTime() + daysFromStartingDay * 24 * 60 * 60 * 1000
  // );
  const dates = [
    startingDay,
    new Date(startingDay.getTime() + 24 * 60 * 60 * 1000),
  ];

  for (let i = 2; i < numberOfDaysInList; i++) {
    dates.push(new Date(startingDay.getTime() + i * 24 * 60 * 60 * 1000));
  }

  const formattedDates = dates.map((date) =>
    date
      .toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/d*\//, "年")
      .replace(/\//g, "月")
  );
  const latestList = formattedDates.map((date) => date + "日");

  // console.log(latestList);
  return latestList;
}
