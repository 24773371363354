const setPhoneAndSNumber = (state, action) => {
  if (action.payload === "snValid") {
    state.CBASerialNumberIsValid = true;
  } else if (action.payload === "snInvalid") {
    state.CBASerialNumberIsValid = false;
  } else if (action.payload === "phValid") {
    state.CBAPhoneIsValid = true;
  } else {
    state.CBAPhoneIsValid = false;
  }

  if (state.CBAPhoneIsValid && state.CBASerialNumberIsValid) {
    state.buttonIsNotActive = false;
    // return;
  } else {
    state.buttonIsNotActive = true;
  }
};
export default setPhoneAndSNumber;
