import { orderActions } from "../../store/orderSlice";
import { useDispatch } from "react-redux";

export default function useSetMNPerrors() {
  const dispatch = useDispatch();
  return function (inputElementName) {
    if (inputElementName === "OTA_ICCD") {
      dispatch(orderActions.setMNPOTAICCD(null));
    } else if (inputElementName === "OTA") {
      dispatch(orderActions.setMNPOTA(null));
    } else if (inputElementName === "reservationNumber") {
      dispatch(orderActions.setMNPReservationNumber(null));
    } else {
      dispatch(orderActions.setMNP_phoneNumber(null));
    }
  };
}
