import ProgressButton from "../commonComponents/ProgressButton.js";
import ScrollToTop from "../ui/ScrollToTop.js";
import AccountCard from "./AccountCard.js";
import WebRegistering from "./WebRegistering.js";
import classes from "./settingUpAccount.module.css";

function SettingUpAccount(props) {
  const proceedToAccountNumber = () => {
    props.onComplete(7);
  };
  return (
    <div className={classes.settingUpAccountContainer}>
      <div className={classes.noticeBox}>
        <p>
          ありがとうございました。
          <br />
          お申込み内容確認のメールを送信しました。
          <br />
          万が一、メールが届かない場合は、迷惑メールフォルダをご確認ください。
          <br />
          それでもメールが見つからない場合は、
          <br />
          お手数ですが、お客様のお名前とメールアドレスを明記の上、
          <b>system@sky7mobile.ne.jp</b>までご連絡ください。
          <br />
        </p>
      </div>
      <ScrollToTop />
      <h2 className={classes.settingUpAccountHeading}>
        お引き落とし口座の設定
      </h2>
      <p className={`${classes.required} ${classes.setUpGuidelineInstruction}`}>
        お引き落とし口座設定を設定してください
      </p>
      <section className={classes.accountCardsContainer}>
        <AccountCard />
        <WebRegistering />
      </section>
      <ProgressButton
        buttonText="申し込みを確定する"
        onStepCompleted={proceedToAccountNumber}
        isDisabled={false}
      />
    </div>
  );
}
export default SettingUpAccount;
