export default function getDaysInMonth(year, month) {
  let numberOfDays;
  let days = [];
  if (year % 4 === 0) {
    switch (month) {
      case 2:
        numberOfDays = 29;
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        numberOfDays = 30;
        break;
      default:
        numberOfDays = 31;
        break;
    }
  } else {
    switch (month) {
      case 2:
        numberOfDays = 28;
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        numberOfDays = 30;
        break;
      default:
        numberOfDays = 31;
        break;
    }
  }

  for (let i = 1; i <= numberOfDays; i++) {
    days.push(i);
  }

  return days;
}
