import Hashes from "jshashes";

async function createHash(customerNumber) {
  let inputArray = ["1192566", "20104", customerNumber, "willrabbit1"];
  const hashInput = inputArray.join("");

  var fsValue = new Hashes.SHA256().hex(hashInput);

  return fsValue;
}

export default createHash;
