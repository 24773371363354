export default function setPlanOptions(state, action) {
  let submittedData = action.payload;
  if (submittedData === "留守番電話") {
    state.answeringMachine = !state.answeringMachine;
  } else if (submittedData === "キャッチホン") {
    state.catchPhone = !state.catchPhone;
  } else {
    state.additionalPacket = !state.additionalPacket;
    state.catchPhone = false;
    state.answeringMachine = false;
  }
}
