function setNames(state, action) {
  const payload = action.payload.type;
  const name = action.payload.name;
  const isAnError = action.payload.isAnError ? action.payload.isAnError : null;
  // console.log(payload);
  if (isAnError) {
    switch (payload) {
      case "normalFirstName":
        state.firstName = null;
        break;
      case "normalLastName":
        state.lastName = null;
        break;
      default:
        break;
    }
  } else {
    switch (payload) {
      case "firstName":
        state.firstName = name;
        break;
      case "lastName":
        state.lastName = name;
        break;
      default:
        break;
    }
  }
}
export default setNames;
