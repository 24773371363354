export default function setDisplayedUploads(idType) {
  if (
    idType === "運転免許証" ||
    idType === "住民基本台帳カード" ||
    idType === "在留カード" ||
    idType === "健康保険証"
  ) {
    return true;
  } else {
    return false;
  }
}
