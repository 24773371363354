import AgreementPageGenProvisions from "./AgreementPageGenProvisions";
import ContractStartEnd from "./ContractStartEnd";
import ExtraInfo from "./ExtraInfo.js";
import Information from "./Information.js";
import MethodsOfUtilization from "./MethodsOfUtilization";
import SuspensionAndCancellation from "./SuspensionAndCancellation";
import classes from "./agreementPage.module.css";

function AgreementPage() {
  return (
    <div className={classes.agreementPageContainer}>
      <h1 className={classes.agreementPageHeading}>
        利用規約
        <hr />
      </h1>
      <section>
        <AgreementPageGenProvisions />
        <ContractStartEnd />
        <Information />
        <MethodsOfUtilization />
        <SuspensionAndCancellation />
        <ExtraInfo />
      </section>
    </div>
  );
}
export default AgreementPage;
