const setHuriganaNames = (state, action) => {
  let payload = action.payload.type;
  let huriganaName = action.payload.name;

  switch (payload) {
    case "firstNameHurigana":
      state.firstNameKana = huriganaName;
      break;
    case "lastNameHurigana":
      state.lastNameKana = huriganaName;
      break;
    default:
      break;
  }
};
export default setHuriganaNames;
