import { useSelector } from "react-redux";
import FileUpload from "../DocumentSubmission/FileUpload";
import ProgressButton from "../commonComponents/ProgressButton";
import classes from "./InitialFeeReceiptPage.module.css";

function InitialFeeReceiptPage(props) {
  const initialFeeReceipt = useSelector(
    (state) => state.order.initialFeeReceipt
  );
  // const storeId = useSelector((state) => state.order.shopCode);

  const contractDocument = useSelector((state) => state.order.contractDocument);

  const handleProceedToNextStage = () => {
    props.onComplete(5);
  };

  return (
    <div className={classes.InitialFeeReceiptPage}>
      <div className={classes.fileUploadContainer}>
        <FileUpload
          elementId={"initialFeeDoc"}
          cardText={"初期費用受領確認書"}
          type={"document"}
          onUploaded={props.onUploaded}
          submittedFile={initialFeeReceipt}
        />
        {/* contract form */}

        <FileUpload
          elementId={"contractDocument"}
          cardText={"申し込み内容確認書兼契約書"}
          type={"document"}
          onUploaded={props.onUploadedContractDocument}
          submittedFile={contractDocument}
          classes={"reduce-mobile-font"}
        />
      </div>

      <div className={classes.buttonContainer}>
        <ProgressButton
          buttonText="プラン内容・入力確認に進む"
          isDisabled={initialFeeReceipt && contractDocument ? false : true}
          onStepCompleted={handleProceedToNextStage}
        />
      </div>
    </div>
  );
}
export default InitialFeeReceiptPage;
