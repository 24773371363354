import { AnimatePresence, motion } from "framer-motion";
import ScrollToTop from "../ui/ScrollToTop";
import ConfirmBeforeApplicationForm from "./ConfirmBeforeApplicationForm";
import classes from "./confirmBeforeApplication.module.css";

function ConfirmBeforeApplication({ onComplete }) {
  return (
    <AnimatePresence>
      <motion.div
        className={classes.applicationStartContainer}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        layout
      >
        <ScrollToTop />
        <div className={classes.confirmBeforeApplication}>
          <div className={classes.confirmHeadingContainer}>
            <h2>お申し込み前のご確認</h2>
          </div>

          <div className={classes.explanationContainer}>
            <h3 className={classes.applicationSectionHeading}>
              必要書類について
            </h3>
            <hr />
            <div className={classes.requiredDocumentsContainer}>
              <p className={classes.subHeading}>本人確認書類</p>
              <p>以下の証明書がご利用になれます</p>

              <ul>
                <li>運転免許証</li>
                <li>マイナンバーカード（個人番号カード）</li>
                <li>住民基本台帳カード ＋ 補助書類</li>
                <li>健康保険証 ＋ 補助書類</li>
                <li>特別永住者証明書</li>
                <li>在留カード</li>
                <li>しょうがい者手帳</li>
              </ul>
              <p>以下の書類が補助書類としてご利用になれます</p>
              <ul>
                <li>住民票</li>
                <li>公共料金支払い領収書</li>
                {/* <li>国民年金手帳</li> */}
              </ul>
              <p>
                ※SIMカード郵送申し込みの場合は顔写真付き身分証明書に限ります
              </p>
            </div>

            <div className={classes.paymentRecsContainer}>
              <p className={classes.subHeading}>お支払い</p>
              <p>以下のいずれかをご用意ください</p>
              <ul>
                <li>キャッシュカード</li>
                <li>通帳＋お届け印</li>
              </ul>
            </div>

            <h3 className={classes.applicationSectionHeading}>
              ご利用予定の端末について
            </h3>
            <hr />
            <div className={classes.aboutDeviceReqs}>
              <p className={classes.subHeading}>電話機について</p>
              <ul>
                <li>
                  電話機につきましては、原則お客様にてご用意いただきます。
                </li>
                <li>一部店舗において、端末の販売も行っております。</li>
                <li>
                  ドコモから販売された機種、またはＳＩＭフリー機種をご用意ください。
                </li>
                <li>
                  ドコモ以外から販売された機種はＳＩＭロック解除が必要です。
                </li>
              </ul>
            </div>
            <div className={classes.networkRestrictions}>
              <p className={classes.subHeading}>ネットワーク利用制限について</p>
              <ul>
                <li>
                  電話機本体の分割支払金や料金等が未払いの場合、
                  <br /> ネットワーク利用制限をされている可能性があります。
                  <br />
                  ご確認の上、契約にお進みください。
                </li>
                <li>
                  <a href="http://nw-restriction.nttdocomo.co.jp/top.php">
                    ご確認サイトはこちら
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <ConfirmBeforeApplicationForm onComplete={onComplete} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
export default ConfirmBeforeApplication;
