import { useSelector } from "react-redux";
import useVerifyPhoneNumber from "../functions/useVerifyPhoneNumber";
import useVerifySerialNumber from "../functions/useVerifySerialNumber";
import DataInput from "./DataInput";
import classes from "./simdetails.module.css";

function SimDetails() {
  const verifySerialNumber = useVerifySerialNumber();
  const verifyPhoneNumber = useVerifyPhoneNumber();

  const simSerialNumber = useSelector((state) => state.order.serialNumber);
  const simPhoneNumber = useSelector((state) => state.order.phoneNumber);

  return (
    <div className={classes.simDetailsContainer}>
      <p className={classes.applicationContainerHeading}>
        ＳＩＭカード情報入力
      </p>
      <hr />
      <div className={classes.simInfoContainer}>
        <DataInput
          type={"text"}
          placeholder={"SIMカード製造番号"}
          name={"serial_number"}
          inputMode={null}
          verifyInput={verifySerialNumber}
          alreadyAvailable={simSerialNumber}
        />

        <DataInput
          type={"text"}
          placeholder={"電話番号"}
          name={"phone_number"}
          inputMode={"numeric"}
          verifyInput={verifyPhoneNumber}
          alreadyAvailable={simPhoneNumber}
        />
      </div>
    </div>
  );
}
export default SimDetails;
