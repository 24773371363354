import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import { orderActions } from "../../store/orderSlice";
import ProgressButton from "../commonComponents/ProgressButton";
import setDisplayedUploads from "../functions/setDisplayedUploads";
import useVerifyDocumentsButton from "../functions/useVerifyDocumentsButton";
import ScrollToTop from "../ui/ScrollToTop";
import DocumentsChecklist from "./DocumentsChecklist";
import FileUpload from "./FileUpload";
import IdType from "./IdType";
import classes from "./documentSubmissionStep.module.css";

function DocumentSubmission(props) {
  const [userIdType, setUserIdType] = useState("運転免許証");
  const dispatch = useDispatch();

  const chosenId = useSelector((state) => state.order.idType);
  const idFront = useSelector((state) => state.order.idFront);
  const idBack = useSelector((state) => state.order.idBack);
  const supportDoc1 = useSelector((state) => state.order.document_1);
  const supportDoc2 = useSelector((state) => state.order.document_2);
  const supportDoc3 = useSelector((state) => state.order.document_3);
  const selections = useSelector((state) => state.order.deliveryMethod);

  const secondUploadNeeded = setDisplayedUploads(chosenId);

  const buttonState = useVerifyDocumentsButton(
    chosenId,
    idFront,
    idBack,
    supportDoc1,
    supportDoc2,
    supportDoc3
  );

  const assignSelectedId = (idType) => {
    dispatch(dispatch(orderActions.setIdType(idType)));
    setUserIdType(idType);
  };
  const handleProceedToNextStage = () => {
    if (selections === "store") {
      props.onComplete(4);
    } else {
      props.onComplete(5);
    }
  };

  return (
    <div className={classes.documentSubmissionStepContainer}>
      <ScrollToTop />
      <h2 className={classes.documentSubmissionHeading}>本人確認書類の提出</h2>
      <div className={classes.documentSubmissionFormContainer}>
        <p className={`${classes.required} ${classes.heading}`}>
          本人確認書類の種類
        </p>

        <div className={classes.idTypeContainer}>
          <IdType onIdSelected={assignSelectedId} />
        </div>
        <p className={`${classes.required} ${classes.heading}`}>
          本人確認書類の添付
        </p>
        <p>
          <span className={classes.documentSubmissionInfo}>
            記載がない場合でも裏面の画像も添付してください。
          </span>
          <span className={classes.documentSubmissionInfo}>
            国際免許は使用できません。
          </span>
        </p>

        <FileUpload
          elementId={"id_upload_1"}
          cardText={`${userIdType}（表面）`}
          type={"id"}
          onUploaded={props.onUploaded}
          submittedFile={idFront}
        />
        {secondUploadNeeded && (
          <FileUpload
            elementId={"id_upload_2"}
            cardText={`${userIdType}（裏面）`}
            type={"id"}
            onUploaded={props.onUploaded}
            submittedFile={idBack}
          />
        )}

        <p className={`${classes.optional} ${classes.heading}`}>
          補助書類の添付{" "}
        </p>
        <p>
          <span className={classes.documentSubmissionInfo}>
            運転免許証に記載された名前や住所が最新でない場合発行から3ヶ月以内の補助書類が必要です。
          </span>
          <span className={classes.documentSubmissionInfo}>
            住民票記載事項証明書か公共料金領収書がご利用になれます。
          </span>
        </p>
        <FileUpload
          elementId={"support_doc_1"}
          cardText={"補助書類 １"}
          type={"document"}
          onUploaded={props.onUploaded}
          submittedFile={supportDoc1}
        />
        <FileUpload
          elementId={"support_doc_2"}
          cardText={"補助書類 2"}
          type={"document"}
          onUploaded={props.onUploaded}
          submittedFile={supportDoc2}
        />
        <FileUpload
          elementId={"support_doc_3"}
          cardText={"補助書類 3"}
          type={"document"}
          onUploaded={props.onUploaded}
          submittedFile={supportDoc3}
        />
      </div>

      {buttonState && <DocumentsChecklist currentId={userIdType} />}
      <div className={classes.buttonContainer}>
        <ProgressButton
          buttonText="プラン内容・入力確認に進む"
          isDisabled={buttonState}
          onStepCompleted={handleProceedToNextStage}
        />
      </div>
    </div>
  );
}
export default DocumentSubmission;
