// import { useState } from "react";

export default function useVerifyDocumentsButton(
  chosenId,
  idFront,
  idBack,
  supportDoc1,
  supportDoc2,
  supportDoc3
) {
  // useEffect
  // const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  if (chosenId === "運転免許証") {
    if (idFront && idBack) {
      return false;
    } else {
      return true;
    }
  } else if (chosenId === "健康保険証") {
    if (idFront && idBack && supportDoc1) {
      return false;
    } else {
      return true;
    }
  } else if (chosenId === "マイナンバーカード") {
    if (idFront) {
      return false;
    } else {
      return true;
    }
  } else if (chosenId === "住民基本台帳カード") {
    if (idFront && idBack && supportDoc1) {
      return false;
    } else {
      return true;
    }
  } else if (chosenId === "特別永住者証明書") {
    if (idFront) {
      return false;
    } else {
      return true;
    }
  } else if (chosenId === "在留カード") {
    if (idFront && idBack) {
      return false;
    } else {
      return true;
    }
  } else {
    if (idFront) {
      return false;
    } else {
      return true;
    }
  }
}
