import { useSelector } from "react-redux";
import useVerifyPhoneTwo from "../functions/useVerifyPhoneTwo";
import CustomerDetailInput from "./CustomerDetailInput";
import classes from "./customerDetailsForm.module.css";

function CustomerPhoneInput() {
  const verifyPhoneNumber = useVerifyPhoneTwo();
  const availableValue = useSelector((state) => state.order.tel2);
  const submitPhoneNumber = (
    data,
    setHasError,
    setErrorText,
    setInputIsValid
  ) => {
    verifyPhoneNumber(data, setHasError, setErrorText, setInputIsValid);
  };
  return (
    <div>
      <label>
        <span className={`${classes.block} ${classes.notRequired}`}>
          連絡が取れる電話番号
        </span>
      </label>
      <CustomerDetailInput
        label={"電話番号"}
        placeholder={"例)08012345678(ハイフンなし)"}
        entryInstruction={"半角数字11桁以内"}
        isHalfWidth
        onEntryDone={submitPhoneNumber}
        submittedValue={availableValue}
        name="optional_phone"
      />
    </div>
  );
}
export default CustomerPhoneInput;
