import SMBCForm from "./SMBCForm";
import classes from "./WebRegistering.module.css";

function WebRegistering() {
  return (
    <div className={classes.webRegisteringContainer}>
      <h2>Ｗｅｂで登録する場合</h2>
      {/* <h2>郵送のお客様</h2> */}
      <p>下のボタンから口座登録にお進みください。</p>
      {/* <p>以下のボタンを押すことで申込み手続きが完了となります。</p> */}
      <p>※SMBCファイナンスのサイトへアクセスします。</p>
      {/* <p>
        この後の口座登録につきましては本部よりメールにてご案内いたしますので、暫くお待ち下さい。
      </p> */}
      <SMBCForm />
    </div>
  );
}
export default WebRegistering;
