import Modal from "../ui/Modal";
import classes from "./loadingComponent.module.css";

function LoadingComponent(props) {
  return (
    <Modal open={props.loadingIsOpen} onChange={() => {}}>
      <div className={classes.noticeBox}>
        <h2>申込内容を送信しています</h2>
        <p>画面を閉じずにしばらくお待ち下さい</p>
        <div className={classes.outerSpin}>
          <div className={classes.innerSpin}></div>
        </div>
      </div>
    </Modal>
  );
}
export default LoadingComponent;
