// import { toHalfWidth } from "henkaku";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";
import classes from "./ImeiComponent.module.css";

function ImeiComponent() {
  // const [wasTouched, setWasTouched] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const [approved, setApproved] = useState(false);

  const [showInstruction, setShowInstruction] = useState(false);

  const dispatch = useDispatch();

  const verifyAndSubmitImei = (e) => {
    setShowInstruction(false);
    const enteredValue = e.target.value.trim();

    if (!enteredValue) {
      setEmptyError(true);
      setErrorText("この項目は必須です");
      setHasError(true);
      setApproved(false);
      setHasInput(false);
      dispatch(orderActions.setImei(null));
    } else {
      let imeiRegex = /^\d{15}$/g;
      const validInput = imeiRegex.test(enteredValue);
      if (validInput) {
        setHasInput(true);
        setApproved(true);
        dispatch(orderActions.setImei(enteredValue));
      } else {
        dispatch(orderActions.setImei(null));
        setEmptyError(true);
        setErrorText("有効な入力を入力してください。");
        setHasError(true);
        setApproved(false);
        setHasInput(true);
      }
    }
  };
  const resetErrors = () => {
    // setWasTouched(true);
    setEmptyError(false);
    setErrorText(null);
    setHasError(false);
    setApproved(false);
    setShowInstruction(true);
  };

  return (
    <div className={classes.ImeiContainer}>
      <p className={classes.labelText}>IMEI番号</p>
      <div className={classes.labelContainer}>
        <input
          type="text"
          name="imei"
          className={`${classes.inputText} ${
            approved ? classes.approved : hasError ? classes.error : null
          }`}
          onBlur={verifyAndSubmitImei}
          onClick={resetErrors}
        />
        <p
          className={`${classes.moveableText} ${
            emptyError ? classes.emptyError : null
          } ${
            hasError
              ? classes.labelError
              : approved
              ? classes.labelapproved
              : null
          } ${hasInput ? classes.moveLabel : null}`}
        >
          IMEI番号
        </p>
      </div>
      <div className={classes.entryContainer}>
        {showInstruction && (
          <p className={classes.entryInstruction}>半角で入力してください。</p>
        )}
        <p className={`${classes.entryInstruction} ${classes.errorArea}`}>
          {errorText}
        </p>
      </div>
    </div>
  );
}
export default ImeiComponent;
