import classes from "./fetchError.module.css";

function FetchError({ message }) {
  return (
    <div className={classes.orderPageErrorContainer}>
      <div className={classes.orderPageErrorSubContainer}>
        <h1>
          {message
            ? message
            : "申し訳ありませんが、お探しの店舗は見つかりませんでした。"}
        </h1>
        <a
          href="https://sky7mobile-shinplan.com/"
          className={classes.returnButtonWrap}
        >
          <button className={classes.returnButton}> 戻る </button>
        </a>
        <a
          href="https://sky7mobile-shinplan.com/"
          className={classes.returnButtonWrap}
        >
          <button className={classes.returnButton}> 戻る </button>
        </a>
      </div>
    </div>
  );
}
export default FetchError;
