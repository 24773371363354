const detailPageExtractions = {
  contractType(contractName) {
    switch (contractName) {
      case "新規申込":
        return "1";
      case "新プラン":
        return "2";
      case "他社からの乗り換え(MNP)":
        return "2";
      case "wifi":
        return "3";
      default:
        return null;
    }
  },
  getDomesticMovedFlag(contractName) {
    switch (contractName) {
      case "新プラン":
        return "1";
      case "他社からの乗り換え(MNP)":
        return "0";
      default:
        return null;
    }
  },
  getPrefectureNumber(prefecture) {
    const { AjaxZip3 } = window;
    let myPrefectures = [...AjaxZip3.PREFMAP];
    const prefNumber = myPrefectures.indexOf(prefecture);
    return prefNumber;
  },
  getPlanNumber(planName) {
    switch (planName) {
      case "音声プラン":
        return "1";
      case "データ＋ＳＭＳプラン":
        return "2";
      case "ポケットWifi":
        return "3";
      case "ポケットWifi2":
        return "3";
      default:
        return "";
    }
  },
  getImmediateDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  },
  formatDate(date) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  },
  getProductNumber(
    applicationType,
    deliveryMethod,
    plan,
    iccidNumber,
    gdNumber,
    Imei
  ) {
    if (applicationType === "1") {
      if (deliveryMethod === "1" && (plan === "1" || plan === "2")) {
        return gdNumber;
      } else {
        return null;
      }
    } else if (applicationType === "2") {
      if (deliveryMethod === "1" && plan === "1") {
        return iccidNumber;
      } else {
        return null;
      }
    } else if (applicationType === "3" && deliveryMethod === "1") {
      return Imei;
    } else {
      return null;
    }
  },
  setSimType(contractName, deliveryMode) {
    //console.log(contractName, deliveryMode);
    if (
      contractName === "新規申込" &&
      (deliveryMode === "1" || deliveryMode === "2")
    ) {
      return "1";
    } else if (
      (contractName === "新プラン" ||
        contractName === "他社からの乗り換え(MNP)") &&
      deliveryMode === "1"
    ) {
      return "3";
    } else if (
      (contractName === "新プラン" ||
        contractName === "他社からの乗り換え(MNP)") &&
      deliveryMode === "2"
    ) {
      return "1";
    } else {
      return null;
    }
  },
};

export default detailPageExtractions;
