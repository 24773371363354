import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";

const useVerifyCustomerName = () => {
  const dispatch = useDispatch();

  return function VerifyCustomerNames(
    data,
    setHasError,
    setErrorText,
    setInputIsValid
  ) {
    let enteredData = data.enteredData;
    let lengthOfInput = enteredData.length;

    if (lengthOfInput > 30) {
      setHasError(true);
      setErrorText("30文字以下で入力してください");
      dispatch(
        orderActions.setCustomerName({
          type: data.name,
          name: data.enteredData,
          isAnError: true,
        })
      );
    } else {
      setHasError(false);
      setErrorText(null);
      setInputIsValid(true);
      if (data.name === "normalFirstName") {
        dispatch(
          orderActions.setCustomerName({
            type: "lastName",
            name: data.enteredData,
            isAnError: false,
          })
        );
      } else {
        dispatch(
          orderActions.setCustomerName({
            type: "firstName",
            name: data.enteredData,
            isAnError: false,
          })
        );
      }
    }
  };
};
export default useVerifyCustomerName;
