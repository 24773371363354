import { useState } from "react";
import { useSelector } from "react-redux";
import ProgressButton from "../commonComponents/ProgressButton";
import ScrollToTop from "../ui/ScrollToTop";
import CustomerAddressEntry from "./CustomerAddressEntry";
import CustomerPhoneInput from "./CustomerPhoneInput";
import DateEntry from "./DateEntry";
import EmailEntry from "./EmailEntry";
import EntryCheckList from "./EntryCheckList";
import GenderSelection from "./GenderSelection";
import HuriganaNameEntry from "./HuriganaNameEntry";
import ImeiComponent from "./ImeiComponent";
import NormalNameEntry from "./NormalNameEntry";
import SimDetails from "./SimDetails";
import classes from "./customerDetailsForm.module.css";

function CustomerDetailsForm(props) {
  const [buttonState, setButtonState] = useState(true);
  const deliveryMode = useSelector((state) => state.order.deliveryMethod);
  const mainApplicationType = useSelector(
    (state) => state.order.mainApplicationType
  );
  const selectedPlan = useSelector((state) => state.order.contractPlan);

  const setButtonDisability = (allStatesValid) => {
    if (allStatesValid) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };
  const handleStepCompleted = () => {
    props.onComplete(3);
  };

  return (
    <div className={classes.customerDetailsContainer}>
      <ScrollToTop />
      <h2 className={classes.customerDetailsHeading}>
        お客様の情報を入力してください
      </h2>
      <div className={classes.formContainer}>
        {selectedPlan === "ポケットWifi" &&
          mainApplicationType === "wifi" &&
          deliveryMode === "store" && <ImeiComponent />}
        {selectedPlan === "ポケットWifi2" &&
          mainApplicationType === "wifi" &&
          deliveryMode === "store" && <ImeiComponent />}
        {(selectedPlan === "音声プラン" ||
          selectedPlan === "データ＋ＳＭＳプラン") &&
          mainApplicationType === "新規申込" &&
          deliveryMode === "store" && <SimDetails />}
        <NormalNameEntry />
        <HuriganaNameEntry />
        <CustomerAddressEntry />
        <DateEntry />
        <GenderSelection />
        <CustomerPhoneInput />
        <EmailEntry />

        <EntryCheckList setButtonStatus={setButtonDisability} />

        <ProgressButton
          buttonText="本人確認書類の提出に進む"
          isDisabled={buttonState}
          onStepCompleted={handleStepCompleted}
        />
      </div>
    </div>
  );
}
export default CustomerDetailsForm;
