import { toFullWidth, toHalfWidth } from "henkaku";
import { useEffect, useRef, useState } from "react";

import { Transition } from "react-transition-group";
import classes from "./customerDetailInput.module.css";

function CustomerDetailInput(props) {
  const [hasInput, setHasInput] = useState(false);
  const [viewInstruction, setViewInstruction] = useState(false);
  const [wasTouched, setWasTouched] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [inputIsValid, setInputIsValid] = useState(false);
  const [hasEmptyInput, setHasEmptyInput] = useState(false);

  const userInput = useRef();
  const entryInstruction = useRef();
  const errorArea = useRef();

  const checkForInputAndSubmitData = (event) => {
    setViewInstruction(false);
    let entry = event.target.value.trim();

    if (!entry && wasTouched && props.required) {
      setHasError(true);
      setHasEmptyInput(true);
      setErrorText(props.emptyError ? props.emptyError : "この項目は必須です");
    } else {
    }
    if (entry) {
      setHasInput(true);

      if (props.isHalfWidth) {
        let halfWidthInput = toHalfWidth(entry);
        userInput.current.value = halfWidthInput;
        props.onEntryDone(
          { enteredData: halfWidthInput, name: props.name },
          setHasError,
          setErrorText,
          setInputIsValid
        );
      } else {
        let fullWidthInput = toFullWidth(entry);
        userInput.current.value = fullWidthInput;
        props.onEntryDone(
          { enteredData: fullWidthInput, name: props.name },
          setHasError,
          setErrorText,
          setInputIsValid
        );
      }
    } else {
      setHasInput(false);
    }
  };
  useEffect(() => {
    let currentElement = document.getElementsByName(props.name)[0];

    currentElement && props.submittedValue
      ? (currentElement.value = props.submittedValue)
      : setHasInput(false);
    if (currentElement && props.submittedValue) {
      setHasEmptyInput(false);
      setViewInstruction(false);
      setHasError(false);
      setInputIsValid(true);
      setHasInput(true);
    } else {
      return;
    }
  }, []);
  const handleFocusChanges = () => {
    setWasTouched(true);
    setHasEmptyInput(false);
    setViewInstruction(true);
    setHasError(false);
    setInputIsValid(false);
  };
  const handleChange = () => {
    setHasInput(true);
  };
  return (
    <div className={classes.customerDetailInputContainer}>
      <div>
        <input
          type={props.type}
          className={`${classes.customerDetailInput} ${
            inputIsValid ? classes.success : hasError ? classes.error : null
          } `}
          placeholder={
            props.placeholder && viewInstruction ? props.placeholder : ""
          }
          name={props.name}
          onBlur={checkForInputAndSubmitData}
          onFocus={handleFocusChanges}
          ref={userInput}
          autoComplete={"new-password"}
          onChange={handleChange}
        />
        <span
          className={`${classes.floatingLabel} ${
            hasInput ? classes.moveLabel : null
          } ${hasEmptyInput ? classes.emptyError : null} ${
            inputIsValid ? classes.validInput : null
          }`}
        >
          {props.label}
        </span>
      </div>
      <div className={classes.entryInfoContainer}>
        {hasError && (
          <p ref={errorArea} className={`${classes.errorArea}`}>
            {errorText}
          </p>
        )}

        {!hasError && (
          <Transition
            in={viewInstruction}
            nodeRef={entryInstruction}
            unmountOnExit
            timeout={500}
          >
            {(state) => (
              <p
                ref={entryInstruction}
                className={`${classes.entryInstruction} ${
                  state === "entering"
                    ? classes.appear
                    : state === "exiting"
                    ? classes.disappear
                    : null
                }`}
              >
                {hasError ? errorText : props.entryInstruction}
              </p>
            )}
          </Transition>
        )}
      </div>
    </div>
  );
}
export default CustomerDetailInput;
