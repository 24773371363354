import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import classes from "./orderPlanCheckList.module.css";

function OrderPlanCheckList(props) {
  const [allValuesValid, setAllVAluesValid] = useState(false);
  // const selectedPlan = useSelector((state) => state.order.contractPlan);
  const deliveryMethod = useSelector((state) => state.order.deliveryMethod);
  const deliveryType = useSelector((state) => state.order.mainApplicationType);
  const MNPserialNum = useSelector((state) => state.order.MNPOTAICCD);
  const MNPOTA = useSelector((state) => state.order.MNPOTA);
  const MNPPhoneNumber = useSelector((state) => state.order.MNP_phoneNumber);
  const MNPReservationNumber = useSelector(
    (state) => state.order.MNPReservationNumber
  );
  const MNPExpDate = useSelector((state) => state.order.MNPExpDate);

  const OTAData =
    deliveryMethod === "store" &&
    (deliveryType === "新プラン" || deliveryType === "他社からの乗り換え(MNP)");
  const NoOTAData =
    deliveryMethod === "mail" &&
    (deliveryType === "新プラン" || deliveryType === "他社からの乗り換え(MNP)");

  useEffect(() => {
    if (OTAData) {
      if (
        MNPserialNum &&
        MNPOTA &&
        MNPPhoneNumber &&
        MNPReservationNumber &&
        MNPExpDate
      ) {
        props.setMNPValidInputs(true);
        setAllVAluesValid(true);
      } else {
        props.setMNPValidInputs(false);
        setAllVAluesValid(false);
      }
    } else if (NoOTAData) {
      if (MNPPhoneNumber && MNPReservationNumber && MNPExpDate) {
        props.setMNPValidInputs(true);
        setAllVAluesValid(true);
      } else {
        props.setMNPValidInputs(false);
        setAllVAluesValid(false);
      }
    } else {
      props.setMNPValidInputs(true);
      setAllVAluesValid(true);
    }
  }, [
    MNPserialNum,
    MNPPhoneNumber,
    MNPReservationNumber,
    MNPExpDate,
    MNPOTA,
    NoOTAData,
    OTAData,
    props,
  ]);

  return (
    <div
      className={`${classes.orderPlanCheckListContainer} ${
        allValuesValid ? classes.allValid : null
      }`}
    >
      {!allValuesValid && (
        <p className={classes.errorsHeading}>
          以下の入力に誤りがあります。内容をご確認ください。
        </p>
      )}
      <ul>
        {!MNPserialNum && OTAData && <li>OTA ICCD</li>}
        {!MNPOTA && OTAData && <li>OTA 仮番号</li>}
        {!MNPPhoneNumber && (OTAData || NoOTAData) && <li>MNP電話番号</li>}
        {!MNPReservationNumber && (OTAData || NoOTAData) && (
          <li>MNP予約番号</li>
        )}
        {!MNPExpDate && (OTAData || NoOTAData) && <li>予約の有効期限</li>}
      </ul>
    </div>
  );
}
export default OrderPlanCheckList;
