import { useSelector } from "react-redux";
import Ripples from "react-ripples";
import classes from "./plansSelection.module.css";

function Plan(props) {
  const currentSelectedBundleAmount = parseInt(
    useSelector((state) => state.order.monthlyFeeWithTax)
  );

  const buttonIsSelected = currentSelectedBundleAmount === props.taxAmount;
  const submitSelectedSpecificPlan = (event) => {
    if (props.type) {
      let planText = props.planDescription;
      props.onSelected(event, planText);
    } else {
      props.onSelected({
        event,
        dataSize: props.dataSize,
        taxAmount: props.taxAmount,
      });
    }
  };
  return (
    <div className={classes.planContainer}>
      <Ripples color="rgba(255, 255, 255, 0.5)" during={1100}>
        <button
          className={`${classes.planPacketOption} ${
            props.buttonActive || buttonIsSelected ? classes.current : null
          }`}
          value={props.taxAmount}
          onClick={submitSelectedSpecificPlan}
        >
          {props.planDescription ? props.planDescription : null}{" "}
          {props.dataSize ? props.dataSize + "GB" : null}{" "}
          {props.taxAmount.toLocaleString()}円 (税込)
        </button>
      </Ripples>
    </div>
  );
}
export default Plan;
