import "./App.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import OrderPage, { storeLoader } from "./OrderPage/OrderPage";

import { Provider } from "react-redux";
import RouteError from "./OrderPage/RouteError";
import ErrorElement from "./OrderPage/errors/ErrorElement";
import RootLayout from "./OrderPage/ui/RootLayout";
import store from "./store/store";
import UsageGuidelines from "./UsageGuidelines/UsageGuidelines";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <RouteError />,
      },
      {
        path: "/:storeNumber",
        element: <OrderPage />,
        loader: storeLoader,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={routes} />
      </Provider>
    </>
  );
}

export default App;
