import Plan from "./Plan";
import classes from "./pocketWifi.module.css";
import { useDispatch, useSelector } from "react-redux";

function PocketWifiPlans(props) {
  const selectedPlan = useSelector((state) => state.order.contractPlan);

  const submitSelectedBundle = (event, planText) => {
    return;
  };

  const is300Wifi = selectedPlan === "ポケットWifi2";
  const is100Wifi = selectedPlan === "ポケットWifi";

  return (
    <div className={classes.dataSmsPlansContainer}>
      {props.unitPrice && (
        <Plan
          taxAmount={14000}
          onSelected={submitSelectedBundle}
          type={"pocketWifi"}
          planDescription={"ポケットWI-FI本体代金"}
          buttonActive={true}
        />
      )}
      {is100Wifi && props.planList && (
        <Plan
          taxAmount={6600}
          onSelected={submitSelectedBundle}
          planDescription={"100GB/月"}
          type={"pocketWifi"}
          buttonActive={is100Wifi}
        />
      )}
      {is300Wifi && props.planList && (
        <Plan
          taxAmount={9350}
          onSelected={submitSelectedBundle}
          planDescription={"300GB/月"}
          type={"pocketWifi"}
          buttonActive={is300Wifi}
        />
      )}
    </div>
  );
}
export default PocketWifiPlans;
