import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toHalfWidth } from "henkaku";
import { orderActions } from "../../store/orderSlice";
import classes from "./dateYearEntry.module.css";

function DateYearEntry(props) {
  const [regError, setRegError] = useState(false);
  const [regErrorText, setRegErrorText] = useState(null);
  const [hasInput, setHasInput] = useState(false);
  const [ageIsValid, setAgeIsValid] = useState(false);
  const [userEntering, setUserIsEntering] = useState(false);
  const [regHasInput, setRegHasInput] = useState(false);
  const yearInputElement = useRef();

  const dispatch = useDispatch();
  const customersEnteredYear = useSelector((state) => state.order.birthYear);

  const setAllErrors = () => {
    setHasInput(false);
    setRegError((prevVal) => (prevVal = false));
    setRegErrorText(null);
    setUserIsEntering(true);
    setAgeIsValid(false);
  };

  const verifyAndSubmitYear = (event) => {
    setUserIsEntering(false);
    const enteredYear = event.target.value.trim();
    const halfWidthEnteredYear = toHalfWidth(enteredYear);
    const numRegex = /^\d+$/g;
    const inputIsValid = numRegex.test(halfWidthEnteredYear);

    if (!enteredYear && !inputIsValid) {
      setRegError(true);
      setRegHasInput(false);
      setRegErrorText("数字4桁で入力してください");
      dispatch(
        orderActions.setBirthDay({
          birthDay: null,
          birthMonth: null,
          bornDay: null,
          birthYear: null,
        })
      );

      props.resetYear(null);
      return;
    } else if (
      (enteredYear && !inputIsValid) ||
      enteredYear.length > 4 ||
      enteredYear.length < 4
    ) {
      setRegError(true);
      setRegHasInput(true);
      setAgeIsValid(false);
      setRegErrorText("数字4桁で入力してください");
      props.resetYear(null);
      dispatch(orderActions.setBirthDay(null));
      return;
    } else {
      setRegError(false);
      setRegHasInput(true);
      setAgeIsValid(true);

      yearInputElement.current.value = halfWidthEnteredYear;
      props.submitYear(
        halfWidthEnteredYear,
        setAgeIsValid,
        setRegErrorText,
        setRegError
      );
    }
  };

  useEffect(() => {
    if (customersEnteredYear) {
      props.submitYear(
        customersEnteredYear,
        setAgeIsValid,
        setRegErrorText,
        setRegError
      );
      setRegHasInput(true);

      document.getElementsByName("customerBirthYear")[0].value =
        customersEnteredYear;
    } else {
      return;
    }
  }, []);

  return (
    <div className={classes.justInput}>
      <input
        ref={yearInputElement}
        type="text"
        className={`${classes.regInput} ${
          regError && !hasInput
            ? classes.inputError
            : ageIsValid
            ? classes.successful
            : null
        }`}
        inputMode="numeric"
        onBlur={verifyAndSubmitYear}
        onFocus={setAllErrors}
        name="customerBirthYear"
      />
      <span
        className={`${classes.floatingLabel} ${
          regHasInput ? classes.moveLabel : null
        } ${
          regError ? classes.labelError : ageIsValid ? classes.success : null
        }`}
      >
        年
      </span>
      <p
        className={` ${classes.normalInfoArea} ${
          regError ? classes.errorArea : null
        }`}
      >
        {regError && !userEntering
          ? regErrorText
          : !regError && userEntering
          ? "半角数字4桁"
          : null}
      </p>
    </div>
  );
}
export default DateYearEntry;
