import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import classes from "./entryCheckList.module.css";
import ChecklistItem from "./ChecklistItem";
import { AnimatePresence, motion } from "framer-motion";

function EntryCheckList(props) {
  const [allValid, setAllValid] = useState(false);
  const normFirstName = useSelector((state) => state.order.firstName);
  const normLastName = useSelector((state) => state.order.lastName);
  const huriFirstName = useSelector((state) => state.order.firstNameKana);
  const huriLastName = useSelector((state) => state.order.lastNameKana);
  const birthDay = useSelector((state) => state.order.birthDay);
  const emailAddress = useSelector((state) => state.order.emailAddress);
  // const emailAdd2 = useSelector((state) => state.order.emailAddress2);
  const postalCode = useSelector((state) => state.order.postalCode);
  const cityTownVillage = useSelector((state) => state.order.cityTownVillage);
  const streetAddress = useSelector((state) => state.order.streetAddress);
  const imeiNumber = useSelector((state) => state.order.Imei);
  const phoneNumber = useSelector((state) => state.order.phoneNumber);
  const serialNumber = useSelector((state) => state.order.serialNumber);
  //
  const deliveryMode = useSelector((state) => state.order.deliveryMethod);
  const selectedPlan = useSelector((state) => state.order.contractPlan);
  const mainApplicationType = useSelector(
    (state) => state.order.mainApplicationType
  );
  const imeiOn =
    selectedPlan === "ポケットWifi" &&
    mainApplicationType === "新規申込" &&
    deliveryMode === "store";
  const simDetails =
    (selectedPlan === "音声プラン" ||
      selectedPlan === "データ＋ＳＭＳプラン") &&
    mainApplicationType === "新規申込" &&
    deliveryMode === "store";

  useEffect(() => {
    if (imeiOn) {
      if (
        normFirstName &&
        normLastName &&
        huriFirstName &&
        huriLastName &&
        birthDay &&
        emailAddress &&
        postalCode &&
        imeiNumber &&
        cityTownVillage &&
        streetAddress
      ) {
        setAllValid(true);
        props.setButtonStatus(true);
      } else {
        setAllValid(false);
        props.setButtonStatus(false);
      }
    } else if (simDetails) {
      if (
        normFirstName &&
        normLastName &&
        huriFirstName &&
        huriLastName &&
        birthDay &&
        emailAddress &&
        postalCode &&
        cityTownVillage &&
        streetAddress &&
        phoneNumber &&
        serialNumber
      ) {
        setAllValid(true);
        props.setButtonStatus(true);
      } else {
        setAllValid(false);
        props.setButtonStatus(false);
      }
    } else {
      if (
        normFirstName &&
        normLastName &&
        huriFirstName &&
        huriLastName &&
        birthDay &&
        emailAddress &&
        postalCode &&
        cityTownVillage &&
        streetAddress
      ) {
        setAllValid(true);
        props.setButtonStatus(true);
      } else {
        setAllValid(false);
        props.setButtonStatus(false);
      }
    }
  }, [
    imeiNumber,
    serialNumber,
    phoneNumber,
    allValid,
    birthDay,
    imeiOn,
    simDetails,
    cityTownVillage,
    emailAddress,
    huriFirstName,
    huriLastName,
    normFirstName,
    normLastName,
    postalCode,
    props,
    streetAddress,
    deliveryMode,
    mainApplicationType,
    selectedPlan,
  ]);

  return (
    <div
      className={`${classes.checklistContainer} ${
        allValid ? classes.allValid : null
      }`}
    >
      {!allValid && <p>以下の入力に誤りがあります。内容をご確認ください。</p>}

      <AnimatePresence>
        <ul className={classes.requirementList}>
          {!imeiNumber && imeiOn && (
            <ChecklistItem checklistLabel="IMEI番号" itemKey={"IMEI番号"} />
          )}
          {!serialNumber && simDetails && (
            <ChecklistItem
              checklistLabel="SIMカード製造番号"
              itemKey={"SIMカード製造番号"}
            />
          )}
          {!phoneNumber && simDetails && (
            <ChecklistItem checklistLabel="電話番号" itemKey={"電話番号"} />
          )}
          {!normFirstName && (
            <ChecklistItem checklistLabel="姓" itemKey={"姓"} />
          )}
          {!normLastName && (
            <ChecklistItem checklistLabel="名" itemKey={"名"} />
          )}
          {!huriFirstName && (
            <ChecklistItem
              checklistLabel="姓(フリガナ)"
              itemKey={"姓(フリガナ)"}
            />
          )}
          {!huriLastName && (
            <ChecklistItem
              checklistLabel="名(フリガナ)"
              itemKey={"名(フリガナ)"}
            />
          )}
          {!postalCode && (
            <ChecklistItem checklistLabel="郵便番号" itemKey={"郵便番号"} />
          )}
          {!cityTownVillage && (
            <ChecklistItem checklistLabel={"市区町村"} itemKey={"市区町村"} />
          )}
          {!streetAddress && (
            <ChecklistItem checklistLabel="丁目番地" itemKey={"丁目番地"} />
          )}
          {!birthDay && (
            <ChecklistItem checklistLabel={"生年月日"} itemKey={"生年月日"} />
          )}
          {!emailAddress && (
            <ChecklistItem
              checklistLabel={"メールアドレス"}
              itemKey={"メールアドレス"}
            />
          )}
        </ul>
      </AnimatePresence>
    </div>
  );
}
export default EntryCheckList;
