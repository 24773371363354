import { useDispatch, useSelector } from "react-redux";

import { orderActions } from "../../store/orderSlice";
import classes from "./gender.module.css";

function GenderSelection() {
  const gender = useSelector((state) => state.order.gender);

  const dispatch = useDispatch();
  const submitGender = (event) => {
    const genderValue = event.target.value;

    dispatch(orderActions.setGender(genderValue));
  };

  return (
    <div className={classes.genderContainer}>
      <span className={` ${classes.required} ${classes.block}`}>性別</span>
      <div className={classes.genders}>
        <label>
          <input
            className={classes.radioFormControl}
            type="radio"
            name="gender"
            value={"男性"}
            onChange={submitGender}
            checked={gender === "男性" ? true : false}
          />
          男性
        </label>
        <label>
          <input
            className={classes.radioFormControl}
            type="radio"
            value={"女性"}
            name="gender"
            onChange={submitGender}
            checked={gender === "女性" ? true : false}
          />
          女性
        </label>
      </div>
    </div>
  );
}
export default GenderSelection;
