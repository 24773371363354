import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";
import Plan from "./Plan";
import classes from "./plansSelection.module.css";

function DataSMSPlans() {
  const dispatch = useDispatch();
  const submitSelectedBundle = (data) => {
    let amountPlusTax = data.event.target.value;
    let bundleSize = data.dataSize;

    dispatch(orderActions.setSelectedBundle({ amountPlusTax, bundleSize }));
  };
  return (
    <div className={classes.dataSmsPlansContainer}>
      <Plan dataSize={3} taxAmount={1650} onSelected={submitSelectedBundle} />
      <Plan dataSize={5} taxAmount={1980} onSelected={submitSelectedBundle} />
      <Plan dataSize={10} taxAmount={2750} onSelected={submitSelectedBundle} />
      <Plan dataSize={20} taxAmount={4180} onSelected={submitSelectedBundle} />
    </div>
  );
}
export default DataSMSPlans;
