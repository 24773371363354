import { useSelector } from "react-redux";
import useVerifyCustomerName from "../functions/useVerifyCustomerName";
import CustomerDetailInput from "./CustomerDetailInput";
import classes from "./customerDetailsForm.module.css";

function NormalNameEntry() {
  const firstName = useSelector((state) => state.order.lastName);
  const lastName = useSelector((state) => state.order.firstName);
  const verifyCustomerNames = useVerifyCustomerName();

  return (
    <div>
      <label>
        <span className={`${classes.block} ${classes.required}`}>契約者名</span>
      </label>
      <span className={classes.inputBlock}>
        <CustomerDetailInput
          type={"text"}
          label={"姓"}
          entryInstruction={"全角30文字以内"}
          onEntryDone={verifyCustomerNames}
          name={"normalFirstName"}
          required={true}
          submittedValue={firstName}
        />
        <CustomerDetailInput
          type={"text"}
          label={"名"}
          entryInstruction={"全角30文字以内"}
          onEntryDone={verifyCustomerNames}
          name={"normalLastName"}
          required={true}
          submittedValue={lastName}
        />
      </span>
    </div>
  );
}
export default NormalNameEntry;
