import { useEffect, useRef, useState } from "react";

import { toHalfWidth } from "henkaku";
import { useSelector } from "react-redux";
import Ripples from "react-ripples";
import { Transition } from "react-transition-group";
import classes from "./prefectures.module.css";

function Prefectures(props) {
  const prefectureList = props.prefectures;
  const [viewPrefs, setViewPrefs] = useState(false);
  const [prefIsSelected, setPrefIsSelected] = useState(false);

  const prefectureListSection = useRef();
  const customerPrefecture = useSelector((state) => state.order.prefecture);

  const submitSelected = (event) => {
    let chosenPref = event.target.value;
    const halfWidthData = toHalfWidth(chosenPref);

    setPrefIsSelected(true);

    props.onEntered(event, halfWidthData);
    document.getElementById("address1").value = halfWidthData;
    setViewPrefs(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickAway);

    return () => {
      document.removeEventListener("click", handleClickAway);
    };
  }, []);
  const handleClickAway = (event) => {
    event.stopPropagation();

    if (!event.target.closest(".prefectureListSectionContainer")) {
      setViewPrefs(false);
    } else {
      return;
    }
  };
  useEffect(() => {
    if (customerPrefecture) {
      setPrefIsSelected(true);
      document.getElementById("address1").value = customerPrefecture;
    } else {
      return;
    }
  }, []);

  return (
    <div
      className={`${classes.prefectureSectionContainer} prefectureListSectionContainer`}
    >
      <div>
        <input
          type="text"
          name="address1"
          id="address1"
          readOnly
          onClick={() => setViewPrefs(true)}
          className={`${classes.selectedPrefecture} ${
            prefIsSelected || props.selectedPref ? classes.approved : null
          }`}
        />
      </div>
      <p
        className={`${classes.moveableLabel} ${
          props.selectedPref || prefIsSelected ? classes.isSelected : null
        }`}
      >
        都道府県
      </p>
      <Transition
        nodeRef={prefectureListSection}
        in={viewPrefs}
        unmountOnExit
        timeout={150}
      >
        <div ref={prefectureListSection}>
          <ul className={classes.prefListContainer}>
            {prefectureList.map((prefecture) => (
              <li key={prefecture}>
                <Ripples>
                  <input
                    type={"text"}
                    value={prefecture}
                    className={classes.prefecture}
                    readOnly
                    onClick={submitSelected}
                  />
                </Ripples>
              </li>
            ))}
          </ul>
        </div>
      </Transition>
    </div>
  );
}
export default Prefectures;
