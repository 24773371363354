import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Ripples from "react-ripples";
import { Transition } from "react-transition-group";
import { orderActions } from "../../store/orderSlice";
import getExpirationDates from "../functions/getExpirationDates";
import classes from "./MNPExpDates.module.css";

export default function MNPExpDates() {
  const [selectedDate, setSelectedDate] = useState();
  const [viewExpDates, setViewExpDates] = useState(false);
  const deliveryMode = useSelector((state) => state.order.deliveryMethod);
  const alreadySubmittedDate = useSelector((state) => state.order.MNPExpDate);

  const dispatch = useDispatch();
  const expList = useRef();

  const possibleExpDates =
    deliveryMode === "store"
      ? getExpirationDates(14, 4)
      : getExpirationDates(6, 10);

  const handleChosenDate = (e) => {
    const chosenDate = e.target.value;
    setSelectedDate(chosenDate);
    dispatch(orderActions.setExpDate(chosenDate));
    hideDates();
  };
  const viewDates = () => {
    setViewExpDates(true);
  };
  const hideDates = () => {
    setViewExpDates(false);
  };

  function handleDocumentClick(event) {
    if (!event.target.closest(".expDatesListContainer")) {
      setViewExpDates(false);
    }
  }

  useEffect(() => {
    if (alreadySubmittedDate) {
      setSelectedDate(alreadySubmittedDate);
    } else {
    }
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className={`${classes.MNPExpDatesContainer} expDatesListContainer`}>
      <p className={classes.expDates}>予約の有効期限</p>
      {deliveryMode === "store" && (
        <p className={classes.expDateExtraInfo}>
          ※店頭でのMNP受付は有効期限は5日以上あることが必要となります
        </p>
      )}
      {deliveryMode === "mail" && (
        <p className={classes.expDateExtraInfo}>
          ※郵送でのMNP受付は有効期限は10日以上あることが必要となります
        </p>
      )}
      <label
        className={`${classes.MNPExpDatesLabel} ${
          selectedDate ? classes.success : null
        }`}
        onClick={viewDates}
      >
        <span>
          {selectedDate ? selectedDate : "有効期限を選択してください"}
        </span>
        <span
          className={`${classes.arrowImage} ${
            viewExpDates ? classes.open : !viewExpDates ? classes.close : null
          }`}
        >
          <img src="/images/arrowpointer.svg" alt="arrow" />
        </span>
      </label>
      <Transition
        nodeRef={expList}
        in={viewExpDates}
        unmountOnExit
        timeout={300}
      >
        {(state) => (
          <div
            ref={expList}
            className={`${classes.MNPExpDatesWrapper} ${
              state === "entering"
                ? classes.attachList
                : state === "exiting"
                ? classes.removeList
                : null
            }`}
          >
            <ul className={classes.MNPExpDates}>
              {possibleExpDates.map((date) => (
                <li key={date}>
                  <Ripples
                    className={classes.rippleComponent}
                    color={"rgba(75, 122, 160,0.5)"}
                    during={1500}
                  >
                    <input
                      type="text"
                      value={date}
                      onClick={handleChosenDate}
                      className={`${classes.MNPExpDate} ${
                        selectedDate === date ? classes.active : null
                      }`}
                      readOnly
                    />
                  </Ripples>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Transition>
    </div>
  );
}
