import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";

function useVerifyCustomerHuriganaName() {
  const dispatch = useDispatch();

  return function VerifyCustomerHuriganaNames(
    data,
    setHasError,
    setErrorText,
    setInputIsValid
  ) {
    let enteredData = data.enteredData;
    let lengthOfInput = enteredData.length;
    let checkKanaRegex = /^[ｧ-ﾝﾞﾟ]+$/g;
    let matches = enteredData.match(checkKanaRegex);

    if (lengthOfInput > 30) {
      setHasError(true);
      setErrorText("30文字以下で入力してください");
    } else {
      if (matches) {
        setHasError(false);
        setErrorText(null);
        setInputIsValid(true);
        if (data.name === "huriganaFirstName") {
          dispatch(
            orderActions.setHuriganaNames({
              type: "lastNameHurigana",
              name: data.enteredData,
            })
          );
        } else {
          dispatch(
            orderActions.setHuriganaNames({
              type: "firstNameHurigana",
              name: data.enteredData,
            })
          );
        }
      } else {
        setHasError(true);
        setErrorText("カタカナのみ入力してください。");
        if (data.name === "huriganaFirstName") {
          dispatch(
            orderActions.setHuriganaNames({
              type: "lastNameHurigana",
              name: null,
            })
          );
        } else {
          dispatch(
            orderActions.setHuriganaNames({
              type: "firstNameHurigana",
              name: null,
            })
          );
        }
      }
    }
  };
}
export default useVerifyCustomerHuriganaName;
