import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { orderActions } from "../../store/orderSlice";
import AllMonths from "../functions/AllMonths";
import getDaysInMonth from "../functions/getDaysIntheMonth";
import DateEntrySection from "./DateEntrySection";
import DateYearEntry from "./DateYearEntry";
import classes from "./dateEntry.module.css";

function DateEntry() {
  const [birthYear, setBirthYear] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthDay, setBirthDay] = useState(null);
  const [generatedList, setGeneratedList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (birthYear && birthDay && birthMonth) {
      dispatch(
        orderActions.setBirthDay({
          birthDay: `${birthYear} 年 ${birthMonth} 月 ${birthDay} 日`,
          birthMonth: birthMonth,
          bornDay: birthDay,
          birthYear,
        })
      );
    } else {
      dispatch(
        orderActions.setBirthDay({
          birthDay: null,
          birthMonth: null,
          bornDay: null,
          birthYear: null,
        })
      );
    }
  }, [birthDay, birthYear, birthMonth, dispatch]);
  const currentYear = new Date().getFullYear();
  const verifyandSubmitYear = (
    enteredYear,
    setAgeIsValid,
    setRegErrorText,
    setRegError
  ) => {
    const userAge = currentYear - enteredYear;
    if (userAge >= 18) {
      setAgeIsValid(true);
      setBirthYear(enteredYear);
      dispatch(orderActions.setCustomerAge(userAge));
      setRegError(false);
    } else {
      setAgeIsValid(false);
      setBirthYear(null);
      setRegErrorText("未成年の方は契約できません");
      dispatch(orderActions.setCustomerAge(null));
      setRegError(true);
    }
  };
  useEffect(() => {
    const generatedList = getDaysInMonth(
      parseInt(birthYear),
      parseInt(birthMonth)
    );

    setGeneratedList(generatedList);
  }, [birthMonth, birthYear]);

  const submitMonth = (userInput) => {
    setBirthMonth(userInput);
  };
  const submitDay = (userInput) => {
    setBirthDay(userInput);
  };
  const customerBirthMonth = useSelector((state) => state.order.birthMonth);
  const customerBirthDay = useSelector(
    (state) => state.order.birthDaySubmitted
  );

  return (
    <div className={classes.dateEntryContainer}>
      <label>
        <span className={`${classes.block} ${classes.required}`}>生年月日</span>
      </label>
      <span className={classes.inputBlock}>
        <DateYearEntry
          submitYear={verifyandSubmitYear}
          resetYear={setBirthYear}
        />
        {/* //month */}
        <DateEntrySection
          readOnly
          symbol={"月"}
          finderClass={"monthsList"}
          notClickable={false}
          listDetail={AllMonths}
          onSelect={submitMonth}
          selectedItem={birthMonth}
          alreadySubmittedValue={customerBirthMonth}
        />
        {/* days */}
        <DateEntrySection
          readOnly
          symbol={"日"}
          finderClass={"daysList"}
          notClickable={birthYear && birthMonth ? false : true}
          listDetail={generatedList}
          onSelect={submitDay}
          selectedItem={birthDay}
          alreadySubmittedValue={customerBirthDay}
        />
      </span>
    </div>
  );
}
export default DateEntry;
