import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { orderActions } from "../../store/orderSlice";
import CustomerAddressInput from "./CustomerAddressInput";
import Prefectures from "./Prefectures";
import classes from "./customerDetailsForm.module.css";

function CustomerAddressEntry() {
  const dispatch = useDispatch();

  const [postalCode, setPostalCode] = useState(false);
  const [userPref, setUserPref] = useState(false);
  const [cityTownVillage, setCityTownVillage] = useState(false);
  const [streetAddress, setStreetAddress] = useState(false);
  const [buildingInfo, setBuildingInfo] = useState(false);
  const [user, setUser] = useState({});
  const [hasAnEntry, setHasAnEntry] = useState(false);

  const myPostalCode = useSelector((state) => state.order.postalCode);
  const myPrefecture = useSelector((state) => state.order.prefecture);
  const myCityTownVillage = useSelector((state) => state.order.cityTownVillage);
  const myStreetAddress = useSelector((state) => state.order.streetAddress);
  const myBuildingInfo = useSelector((state) => state.order.buildingDetails);

  const { AjaxZip3 } = window;
  let myPrefectures = [...AjaxZip3.PREFMAP];
  myPrefectures.shift();

  const onBlurZipcode = (name, value) => {
    const hasTheRightLength = value.length === 7;
    if (hasTheRightLength) {
      const params = user;
      params[name] = value;

      setUser({ params });

      AjaxZip3.zip2addr("postCodeH", "", "address1", "address2", "address3");
      AjaxZip3.onSuccess = () => {
        setCityTownVillage(true);
        setPostalCode(true);
        setStreetAddress(true);
        setUserPref(true);

        submitAddressCode(value);
        setHasAnEntry(true);
      };

      setUser((user) => {
        let details = {
          ...user,
          address1: document.getElementById("address1").value,
          address2: document.getElementById("address2").value,
          address3: document.getElementById("address3").value,
        };

        return details;
      });

      return;
    } else {
      return;
    }
  };
  const submitAddressCode = (value) => {
    setTimeout(() => {
      let address1 = document.getElementById("address1").value;
      let address2 = document.getElementById("address2").value;
      let address3 = document.getElementById("address3").value;

      dispatch(orderActions.setPostalCode(value));
      dispatch(orderActions.setPrefecture(address1));
      dispatch(orderActions.setCityTownVillage(address2));
      dispatch(orderActions.setStreetAddress(address3));
    }, 1000);
  };
  const submitPostalCode = () => {
    // console.log("PC");
  };
  const submitPrefecture = (event, prefec) => {
    dispatch(orderActions.setPrefecture(prefec));
  };
  const submitCityTownVillage = (event, value) => {
    if (!value) {
      dispatch(orderActions.setCityTownVillage(null));
      setCityTownVillage(false);
    } else {
      dispatch(orderActions.setCityTownVillage(value));
      setCityTownVillage(true);
    }
  };
  const submitStreetAddress = (event, value) => {
    if (!value) {
      dispatch(orderActions.setStreetAddress(null));
      setStreetAddress(false);
    } else {
      dispatch(orderActions.setStreetAddress(value));
      setStreetAddress(true);
    }
  };
  const submitBuildingInfo = (event, value) => {
    if (!value) {
      dispatch(orderActions.setBuildingInfo(null));
      setBuildingInfo(false);
    } else {
      dispatch(orderActions.setBuildingInfo(value));
      setBuildingInfo(true);
    }
  };

  return (
    <div>
      <label className={classes.label}>
        <span className={`${classes.block} ${classes.required}`}> 住所</span>
      </label>

      <CustomerAddressInput
        label={"郵便番号"}
        entryInstruction={"半角数字7文字以内"}
        required
        isHalfWidth
        name={"postCodeH"}
        size={"8"}
        maxLength={"8"}
        emptyErrorText={"郵便番号は必須項目です"}
        onEntered={onBlurZipcode}
        valueAccepted={postalCode}
        submittedValue={myPostalCode}
      />
      <span className={classes.inputBlock}>
        <Prefectures
          prefectures={myPrefectures}
          onEntered={submitPrefecture}
          selectedPref={userPref}
        />

        <CustomerAddressInput
          label={"市区町村"}
          required
          isFullWidth
          emptyErrorText={"この項目は必須項目です"}
          entryInstruction={"全角50文字以内"}
          name={"address2"}
          id={"address2"}
          onEntered={submitCityTownVillage}
          hasExternalEntry={hasAnEntry}
          valueAccepted={cityTownVillage}
          submittedValue={myCityTownVillage}
        />
      </span>
      <CustomerAddressInput
        label={"丁目番地"}
        entryInstruction={"全角50文字以内"}
        emptyErrorText={"この項目は必須項目です"}
        required
        isFullWidth
        name={"address3"}
        id={"address3"}
        onEntered={submitStreetAddress}
        hasExternalEntry={hasAnEntry}
        valueAccepted={streetAddress}
        submittedValue={myStreetAddress}
      />
      <CustomerAddressInput
        label={"建物名・部屋番号など"}
        entryInstruction={"全角50文字以内"}
        onEntered={submitBuildingInfo}
        isFullWidth
        valueAccepted={buildingInfo}
        submittedValue={myBuildingInfo}
        name={"buildingInfo"}
      />
    </div>
  );
}
export default CustomerAddressEntry;
