import { useSelector } from "react-redux";
import Modal from "../ui/Modal";
import calculateCurrentSelectionCost from "./calculateCurrentSelectionCost";
import classes from "./currentSelectionDetails.module.css";
import getNextMonth from "./getNextMonth";

export default function CurrentSelectionDetails(props) {
  const contractPlan = useSelector((state) => state.order.contractPlan);
  const planPricing = useSelector((state) => state.order.monthlyFeeWithTax);
  const answeringMachine = useSelector((state) => state.order.answeringMachine);
  const catchPhone = useSelector((state) => state.order.catchPhone);
  const dataSize = useSelector((state) => state.order.planDataSize);

  const { detailResult } = props;

  const presentMonth = new Date().getMonth() + 1;

  const closeModal = () => {
    props.onCloseModal();
  };

  const nextMonth = getNextMonth();

  let date = new Date();
  let dateY = date.getFullYear();
  let dateM = date.getMonth() + 1;
  let dateD = date.getDate();

  return (
    <Modal open={props.isOpen} onChange={closeModal}>
      <div className={classes.detailsBox}>
        <div className={classes.detailSection}>
          <div className={classes.monthlyFee}>
            <dl>
              <dt>毎月の料金目安</dt>
              <dd> {detailResult.soleMonthlyFee.toLocaleString()}円(税込)</dd>
            </dl>
          </div>
          <hr />
          <h3 className={classes.feeFromJan}>1月以降の月額料金の目安です</h3>
          <p className={classes.breakdownHeading}>内訳</p>
          <div className={classes.selectionTermDef}>
            <dl>
              <dt>{nextMonth}月利用料金:</dt>
              <dd>{detailResult.soleMonthlyFee.toLocaleString()}円</dd>
            </dl>
          </div>
          {(catchPhone || answeringMachine) && (
            <div className={classes.selectionTermDef}>
              <dl>
                <dt>オプション:</dt>
                <dd>
                  {catchPhone && <span>キャッチホン 440円</span>}
                  {catchPhone && answeringMachine && (
                    <span>
                      ,<br />
                    </span>
                  )}
                  {answeringMachine && <span>留守番電話 550円</span>}
                </dd>
              </dl>
            </div>
          )}
        </div>
        <div className={classes.detailSection}>
          <p className={classes.detailHeading}>契約時にお支払い料金</p>
          <p className={classes.priceRange}>
            {detailResult.totalFeeFrom.toLocaleString("en-US")}円(税込) ～{" "}
            {detailResult.totalFeeTo.toLocaleString("en-US")}円(税込)
          </p>
          {/* //wifi */}
          {detailResult.selectedPlan.includes("ポケットWifi") && (
            <dl>
              <dt>端末代金 :</dt>
              <dd>14,000円(税込)</dd>
            </dl>
          )}
          {/* wifiend */}
          {(detailResult.showPriceForRemainingDays ||
            detailResult.selectedPlan.includes("ポケットWifi")) && (
            <dl>
              <dt>{presentMonth}月日割料金:</dt>
              <dd>
                {detailResult.dailyRateForRemainingMonth.toLocaleString(
                  "en-US"
                )}
                円
              </dd>
            </dl>
          )}
          <dl>
            <dt>{nextMonth}月利用料金:</dt>
            <dd>{detailResult.onlyPlanFee.toLocaleString()}円</dd>
          </dl>

          {detailResult.catchPhone && (
            <dl>
              <dt>{nextMonth}ご利用分キャッチホン:</dt>
              <dd>440円</dd>
            </dl>
          )}
          {detailResult.answeringMachine && (
            <dl>
              <dt>{nextMonth}ご利用分留守番電話:</dt>
              <dd>550円</dd>
            </dl>
          )}

          <dl>
            <dt>契約事務手数料:</dt>
            <dd>
              {detailResult.administrationFee > 0
                ? `${detailResult.administrationFee.toLocaleString()}円`
                : "無料"}
            </dd>
          </dl>
          <dl>
            <dt>その他諸費用* :</dt>
            <dd>330円～880円</dd>
          </dl>
        </div>
        <div className={`${classes.detailSection} ${classes.listContainer}`}>
          <h3>注意事項</h3>
          <ul>
            <li>
              初月の料金目安は本日{dateY}/{dateM}/{dateD}
              に開通した場合の目安です。開通日によって初月の料金は変動します。
              <br />
              ※店舗によって諸費用が変動します。詳細はお近くの店舗へお問い合わせください。{" "}
            </li>
            <li>
              別途、ユニバーサル料及び電話リレーサービス料【2～4円程度】が必要となります。
            </li>
            <li>
              0570/104等の有料通話やSMS送信を利用すると別途料金が発生いたします。
            </li>
            <li>
              当月のお日割り料金が3,300円(税込)以下の場合は、初期費用に合わせてお支払いいただきます。
            </li>
          </ul>
        </div>
        <button className={classes.closeButton} onClick={closeModal}>
          <img src="/images/close.svg" alt="close modal button" />
        </button>
      </div>
    </Modal>
  );
}
