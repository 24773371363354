import { toFullWidth, toHalfWidth } from "henkaku";
import { useEffect, useRef, useState } from "react";

import { Transition } from "react-transition-group";
import classes from "./customerAddressInput.module.css";

const CustomerAddressInput = (props) => {
  const [hasInput, setHasInput] = useState(false);
  const [viewInstruction, setViewInstruction] = useState(false);
  const [wasTouched, setWasTouched] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [inputIsValid, setInputIsValid] = useState(false);
  const [hasEmptyInput, setHasEmptyInput] = useState(false);

  const userInput = useRef();
  const entryInstruction = useRef();
  const errorArea = useRef();

  const checkForInputAndSubmitData = (event) => {
    setViewInstruction(false);

    const enteredAddressItem = event.target.value.trim();

    if (props.isHalfWidth && enteredAddressItem) {
      setHasInput(true);
      const halfWidthData = toHalfWidth(enteredAddressItem);
      userInput.current.value = halfWidthData;

      props.onEntered(event, halfWidthData);
    } else if (props.isFullWidth && enteredAddressItem) {
      setHasInput(true);
      const fullWidthData = toFullWidth(enteredAddressItem);
      userInput.current.value = fullWidthData;
      props.onEntered(event, fullWidthData);
    } else if (
      (props.isFullWidth || props.isHalfWidth) &&
      !enteredAddressItem &&
      props.required
    ) {
      setHasEmptyInput(true);
      setViewInstruction(false);
      setHasError(true);
      setInputIsValid(false);
      setErrorText(props.emptyErrorText);

      props.onEntered(event, enteredAddressItem);
    } else {
      setHasInput(false);
      props.onEntered(event, enteredAddressItem);
      return;
    }
  };

  useEffect(() => {
    let currentElement = document.getElementsByName(props.name)[0];

    currentElement && props.submittedValue
      ? (currentElement.value = props.submittedValue)
      : setWasTouched(false);
    if (currentElement && props.submittedValue) {
      setHasEmptyInput(false);
      setViewInstruction(false);
      setHasError(false);
      setInputIsValid(true);
      setHasInput(true);
    } else {
      return;
    }
  }, []);

  const handleFocusChanges = () => {
    setWasTouched(true);
    setHasEmptyInput(false);
    setViewInstruction(true);
    setHasError(false);
    setInputIsValid(false);
  };

  return (
    <div className={classes.customerDetailInputContainer}>
      <div>
        <input
          type={props.type}
          className={`${classes.customerDetailInput} ${
            inputIsValid || props.valueAccepted
              ? classes.success
              : hasError
              ? classes.error
              : undefined
          } `}
          placeholder={
            props.placeholder && viewInstruction ? props.placeholder : ""
          }
          name={props.name}
          onBlur={checkForInputAndSubmitData}
          ref={userInput}
          autoComplete="new-password"
          onFocus={handleFocusChanges}
          id={props.id}
        />
        <span
          className={`${classes.floatingLabel} ${
            hasInput || props.hasExternalEntry ? classes.moveLabel : null
          } ${hasEmptyInput ? classes.emptyError : null}`}
        >
          {props.label}
        </span>
      </div>
      <div className={classes.entryInfoContainer}>
        {hasError && (
          <p ref={errorArea} className={`${classes.errorArea}`}>
            {errorText}
          </p>
        )}

        {!hasError && (
          <Transition
            in={viewInstruction}
            nodeRef={entryInstruction}
            unmountOnExit
            timeout={500}
          >
            {(state) => (
              <p
                ref={entryInstruction}
                className={`${classes.entryInstruction} ${
                  state === "entering"
                    ? classes.appear
                    : state === "exiting"
                    ? classes.disappear
                    : null
                }`}
              >
                {hasError ? errorText : props.entryInstruction}
              </p>
            )}
          </Transition>
        )}
      </div>
    </div>
  );
};
export default CustomerAddressInput;
