import { useDispatch, useSelector } from "react-redux";

import Plan from "./Plan";
import classes from "./orderPlanSelection.module.css";
import { orderActions } from "../../store/orderSlice";

function PlanOptions() {
  const dispatch = useDispatch();

  const submitOption = (event, planText) => {
    if (planText === "留守番電話") {
      dispatch(orderActions.setSelectedPlanOptions(planText));
    } else if (planText === "キャッチホン") {
      dispatch(orderActions.setSelectedPlanOptions(planText));
    } else {
      dispatch(orderActions.setSelectedPlanOptions(planText));
    }
  };

  const catchPhoneActive = useSelector((state) => state.order.catchPhone);
  const answeringMachineActive = useSelector(
    (state) => state.order.answeringMachine
  );

  return (
    <div className={classes.packetOptionsContainer}>
      <p className={`${classes.packetSelectionHeading} ${classes.required}`}>
        オプション
      </p>
      <div className={classes.planPacketOptions}>
        <Plan
          taxAmount={550}
          planDescription={"留守番電話"}
          onSelected={submitOption}
          type={"option"}
          buttonActive={answeringMachineActive}
        />
        <Plan
          taxAmount={440}
          planDescription={"キャッチホン"}
          onSelected={submitOption}
          type={"option"}
          buttonActive={catchPhoneActive}
        />
        {/* <Plan
          taxAmount={1100}
          planDescription={"パケット追加（前入金制）"}
          onSelected={submitOption}
          type={"option"}
          buttonActive={additionalPacket}
        /> */}
      </div>
    </div>
  );
}
export default PlanOptions;
