const getIDNumber = (idName) => {
  switch (idName) {
    case "運転免許証":
      return "1";
    case "マイナンバーカード":
      return "2";
    case "住民基本台帳カード":
      return "3";
    case "健康保険証":
      return "4";
    case "特別永住者証明書":
      return "5";
    case "在留カード":
      return "6";
    case "しょうがい者手帳":
      return "7";
    default:
      return "1";
  }
};
export default getIDNumber;
