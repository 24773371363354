import { useEffect, useState } from "react";

import { toHalfWidth } from "henkaku";
import Ripples from "react-ripples";
import classes from "./dateEntry.module.css";

function DateEntrySection(props) {
  const [listIsViewable, setListIsViewable] = useState(false);
  const [itemIsSelected, setItemIsSelected] = useState(false);

  const elementsClassName = props.finderClass;

  const toggleItemsView = (e) => {
    e.stopPropagation();
    setListIsViewable(true);
  };

  const submitSelection = (event) => {
    const userInput = event.target.value;
    const halfWidthInput = toHalfWidth(userInput);

    props.onSelect(halfWidthInput);
    setItemIsSelected(true);
    setListIsViewable(false);
  };
  //
  function handleDocumentClick(event) {
    event.stopPropagation();
    if (!event.target.closest("." + elementsClassName)) {
      setListIsViewable(false);
    } else {
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (props.alreadySubmittedValue) {
      setItemIsSelected(true);
      props.onSelect(props.alreadySubmittedValue);
    } else {
      return;
    }
  }, []);
  return (
    <div className={classes.dateSectionContainer}>
      <button
        className={`${classes.listLabel} ${props.finderClass} ${
          itemIsSelected ? classes.success : null
        } `}
        onClick={toggleItemsView}
        disabled={props.notClickable}
      >
        <span id={props.finderClass}>
          {props.selectedItem ? props.selectedItem : null}
        </span>
        <span>
          <img src="/images/arrowpointer.svg" alt="arrow direction" />
        </span>
        <span
          className={`${classes.selectLabel} ${
            itemIsSelected ? classes.moveLabel : null
          }`}
        >
          {props.symbol}
        </span>
      </button>

      {listIsViewable && (
        <div className={classes.dateSectionInputContainer}>
          {props.listDetail.map((month) => (
            <Ripples
              key={month}
              color="#90b8cf"
              during={1000}
              className={classes.rippleWrap}
            >
              <input
                key={month}
                type="text"
                className={classes.InputItem}
                readOnly={props.readOnly}
                value={month ? month : ""}
                onClick={submitSelection}
              />
            </Ripples>
          ))}
        </div>
      )}
    </div>
  );
}
export default DateEntrySection;
