const setBundle = (state, action) => {
  let payload = action.payload;

  let amountPlusTax = payload.amountPlusTax;
  let bundleSize = payload.bundleSize;

  state.monthlyFeeWithTax = amountPlusTax;
  state.planDataSize = bundleSize;
};
export default setBundle;
