import SubscriptionApplication from "./SubscriptionApplication";
import classes from "./applicationHome.module.css";

function ApplicationHome({ viewControl, storeInfo }) {
  return (
    <div className={classes.mainContentSection}>
      <div>
        <SubscriptionApplication
          viewControl={viewControl}
          storeInfo={storeInfo}
        />
      </div>
    </div>
  );
}
export default ApplicationHome;
