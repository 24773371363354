import { useSelector } from "react-redux";
import useSubmitEmail from "../functions/useSubmitEmail";
import CustomerDetailInput from "./CustomerDetailInput";
import classes from "./emailentry.module.css";

function EmailEntry() {
  const verifyAndSubmitEmail = useSubmitEmail();

  const handleEmailSubmission = (
    data,
    setHasError,
    setErrorText,
    setInputIsValid
  ) => {
    verifyAndSubmitEmail(data, setHasError, setErrorText, setInputIsValid);
  };
  const myEmail = useSelector((state) => state.order.emailAddress);

  return (
    <div className={classes.emailEntry}>
      <label>
        <span className={`${classes.block} ${classes.required}`}>
          メールアドレス
        </span>
      </label>
      <div>
        <ul className={classes.instructionList}>
          <li>
            <span className={classes.importantInstruction}>
              キャリアメールはご利用いただけません
            </span>
            <br />
            【docomo.ne.jp/softbank.jp.ezweb.ne.jp 等】{/* </span> */}
          </li>

          <li className={classes.instruction}>
            Gmail や yahoo
            メールなどキャリアメール以外のメールアドレスを入力して下さい。
          </li>
        </ul>
      </div>
      <div className={classes.inputWrapper}>
        <CustomerDetailInput
          label={"メールアドレス"}
          placeholder={"skysevenmobile@gmail.com"}
          type={"email"}
          name="customer_email"
          onEntryDone={handleEmailSubmission}
          required
          emptyError={"メールアドレスは必須項目です"}
          isHalfWidth
          entryInstruction={"半角256文字以内"}
          submittedValue={myEmail}
        />
        <CustomerDetailInput
          label={"メールアドレス(確認用)"}
          placeholder={"skysevenmobile@gmail.com"}
          type={"email"}
          onEntryDone={handleEmailSubmission}
          required
          isHalfWidth
          name="confirm_customer_email"
          emptyError={"メールアドレスは必須項目です"}
          submittedValue={myEmail}
        />
      </div>
    </div>
  );
}
export default EmailEntry;
