import { motion, useIsPresent } from "framer-motion";
import React from "react";

function ChecklistItem(props) {
  const isPresent = useIsPresent();

  const animations = {
    style: {
      position: isPresent ? "static" : "absolute",
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { opacity: 0, scale: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40 },
  };
  return (
    <motion.li layout {...animations} key={props.itemKey}>
      {props.checklistLabel}
    </motion.li>
  );
}

export default ChecklistItem;
