import { toHalfWidth } from "henkaku";
import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";

const useVerifyPhoneTwo = () => {
  const dispatch = useDispatch();

  return function verifyPhoneNumber(
    data,
    setHasError,
    setErrorText,
    setInputIsValid
  ) {
    let enteredValue = data.enteredData;
    let hasRightLength = enteredValue.length === 11;

    let serialRegex = /^(070|080|090)\d+-?\d+-?\d+$/g;

    if (enteredValue && enteredValue.match(serialRegex) && hasRightLength) {
      let halfWidthData = toHalfWidth(enteredValue);
      setHasError(false);
      setErrorText(null);
      setInputIsValid(true);
      dispatch(orderActions.setOptionalPhoneNumber(halfWidthData));
      return;
    } else {
      setHasError(true);
      setErrorText("電話番号の形式が正しくありません");
      setInputIsValid(false);
      dispatch(orderActions.setOptionalPhoneNumber(null));
      return;
    }
  };
};
export default useVerifyPhoneTwo;
