import { useSelector } from "react-redux";
import useVerifyCustomerHuriganaName from "../functions/useVerifyCustomerHuriganaName";
import CustomerDetailInput from "./CustomerDetailInput";
import classes from "./customerDetailsForm.module.css";

function HuriganaNameEntry() {
  const kanaFirstName = useSelector((state) => state.order.lastNameKana);
  const kanaLastName = useSelector((state) => state.order.firstNameKana);

  const verifyHuriganaNames = useVerifyCustomerHuriganaName();
  return (
    <div>
      <label>
        <span className={`${classes.block} ${classes.required}`}>
          契約者名【フリガナ】
        </span>
      </label>
      <span className={classes.inputBlock}>
        <CustomerDetailInput
          label={"セイ"}
          isHalfWidth={true}
          entryInstruction={"半角力ナ30文字以内"}
          onEntryDone={verifyHuriganaNames}
          name={"huriganaFirstName"}
          required={true}
          submittedValue={kanaFirstName}
        />
        <CustomerDetailInput
          label={"メイ"}
          isHalfWidth={true}
          entryInstruction={"半角力ナ30文字以内"}
          onEntryDone={verifyHuriganaNames}
          name={"huriganaLastName"}
          required={true}
          submittedValue={kanaLastName}
        />
      </span>
    </div>
  );
}
export default HuriganaNameEntry;
