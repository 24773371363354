import { useRef } from "react";
import Ripples from "react-ripples";
import classes from "./orderPlan.module.css";

function OrderPlan(props) {
  let currentButton = useRef();

  const handleSubmitPlan = (event) => {
    let currentPlan = currentButton.current.value;
    props.onPlanSelected(currentPlan);
  };

  const isActive = props.activeContract === props.value;

  return (
    <div className={classes.orderPlanContainer}>
      <Ripples color="rgba(255, 255, 255, 0.5)" during={1200}>
        <button
          className={`${classes.orderPlanItem} ${
            isActive ? classes.buttonActive : ""
          }`}
          onMouseUp={handleSubmitPlan}
          value={props.value}
          ref={currentButton}
          disabled={props.isDisabled}
        >
          <span className={classes.planIconsContainer}>
            {props.images.map((image) => (
              <img
                src={`/images/${image}`}
                key={image}
                alt="internet"
                className={classes.planIcon}
              />
            ))}
          </span>
          <p className={classes.mainPlanText}>{props.mainPlanText}</p>
          <p className={classes.planDetails}>{props.planDetails}</p>
          {props.newPlanDetails && (
            <p className={classes.planDetails}>{props.newPlanDetails}</p>
          )}
          <p className={classes.planStartingPrice}>{props.planStartingPrice}</p>
        </button>
      </Ripples>
    </div>
  );
}
export default OrderPlan;
