import { toHalfWidth } from "henkaku";
import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";

const useVerifyPhoneNumber = () => {
  const dispatch = useDispatch();

  return function verifyPhoneNumber(
    event,
    wasTouched,
    setErrorText,
    setHasError,
    setIsVerified
  ) {
    let enteredValue = event.target.value.trim();
    let hasRightLength = enteredValue.length === 11;

    setHasError(false);
    setErrorText(null);
    setIsVerified(false);

    let serialRegex = /^(070|080|090)\d+-?\d+-?\d+$/g;

    if (wasTouched && !event.target.value) {
      setHasError(true);
      setErrorText("この項目は必須です");
      dispatch(orderActions.setPhoneAndSNumberValidity());
      dispatch(orderActions.setPhoneNumber(null));
      return false;
    } else if (
      wasTouched &&
      enteredValue &&
      enteredValue.match(serialRegex) &&
      hasRightLength
    ) {
      let halfWidthData = toHalfWidth(enteredValue);
      setHasError(false);
      setErrorText(null);
      setIsVerified(true);
      dispatch(orderActions.setPhoneAndSNumberValidity("phValid"));
      dispatch(orderActions.setPhoneNumber(halfWidthData));
      return true;
    } else {
      setHasError(true);
      setErrorText("電話番号の形式が正しくありません");
      setIsVerified(false);
      dispatch(orderActions.setPhoneAndSNumberValidity());
      dispatch(orderActions.setPhoneNumber(null));
      return false;
    }
  };
};
export default useVerifyPhoneNumber;
