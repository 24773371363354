import { toFullWidth, toHalfWidth } from "henkaku";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import classes from "./SMBCForm.module.css";
import createHash from "./createHash";

function SMBCForm() {
  const [fsValue, setFsValue] = useState("");

  const customerEmail = useSelector((state) => state.order.emailAddress);
  const customerPostalCode = useSelector(
    (state) => state.order.postalCode
  ).toString();
  const customerCityTownVillage = useSelector(
    (state) => state.order.cityTownVillage
  );
  const customerStreetAddress = useSelector(
    (state) => state.order.streetAddress
  ).toString();
  const customerPrefecture = useSelector((state) => state.order.prefecture);
  const buildingInfo = useSelector((state) => state.order.buildingDetails);
  const customerName =
    useSelector((state) => state.order.lastName) +
    useSelector((state) => state.order.firstName);
  const customerNameKana =
    useSelector((state) => state.order.lastNameKana) +
    useSelector((state) => state.order.firstNameKana);
  const storeID = useSelector((state) => state.order.shopCode).toString();
  const applicationAccountNumber = useSelector(
    (state) => state.order.applicationAccountNumber
  ).toString();

  useEffect(() => {
    async function getHash() {
      let hashedFs = await createHash(applicationAccountNumber);
      setFsValue(hashedFs);
    }
    getHash();
  }, [applicationAccountNumber]);

  return (
    <div className={classes.SMBCFormContainer}>
      <form
        action="https://www.paymentstation.jp/customer/sf/at/kokkzmoshikomi/begin.do"
        method="post"
        acceptCharset="shift_jis"
        className={classes.payForm}
      >
        <input data-v-5500cda5="" type="hidden" name="version" value="130" />
        <input data-v-5500cda5="" type="hidden" name="bill_method" value="01" />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="kessai_id"
          value={toHalfWidth("0101")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="shop_cd"
          value={toHalfWidth("1192566")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="syuno_co_cd"
          value={toHalfWidth("20104")}
        />
        <input data-v-5500cda5="" type="hidden" name="shoporder_no" value="" />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="koushin_kbn"
          value={toHalfWidth("0")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_no"
          value={applicationAccountNumber}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_name"
          value={toFullWidth(customerName)}
          _mstvisible="11"
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_kana"
          value={toHalfWidth(customerNameKana)}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_zip"
          value={toHalfWidth(customerPostalCode)}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_adr_1"
          value={toHalfWidth(customerPrefecture)}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_adr_2"
          value={toHalfWidth(customerCityTownVillage)}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_adr_3"
          value={toHalfWidth(customerStreetAddress)}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_adr_4"
          value={buildingInfo ? toHalfWidth(buildingInfo) : toHalfWidth("")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_mail"
          value={toHalfWidth(customerEmail)}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="bill_mail_kbn"
          value={toHalfWidth("0")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="redirect_kbn"
          value="0"
          _mstvisible="11"
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="shop_phon_hyoji_kbn"
          value={toHalfWidth("0")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="shop_mail_hyoji_kbn"
          value={toHalfWidth("0")}
        />
        <input
          data-v-5500cda5=""
          type="hidden"
          name="fs"
          value={toHalfWidth(fsValue)}
          _mstvisible="11"
        />
        <input
          type="hidden"
          name="shop_link"
          value={`https://order.sky7mobile-shinplan.com/${storeID}?status=success&email=${customerEmail}&customer_code=${applicationAccountNumber}`}
        />
        <input
          type="hidden"
          name="shop_error_link"
          value={`https://order.sky7mobile-shinplan.com/${storeID}?status=failure&email=${customerEmail}&customer_code=${applicationAccountNumber}`}
        />

        <button type="submit" className={classes.submitButton}>
          WＥＢで口座登録を始める
        </button>
      </form>
    </div>
  );
}
export default SMBCForm;
