import { useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import classes from "./idType.module.css";
import ClickAwayListener from "./ClickAwayListener";

function IdType(props) {
  const [userSelectedId, setUserSelectedId] = useState("運転免許証");
  const [viewPossibleIds, setViewPossibleIds] = useState(false);
  const selectedId = useSelector((state) => state.order.idType);
  const deliveryMethod = useSelector((state) => state.order.deliveryMethod);

  const idList = useRef();

  const showList = () => {
    setViewPossibleIds(true);
  };
  const hideList = () => setViewPossibleIds(false);
  const submitSelection = (e) => {
    const selectedId = e.target.value;
    const idTextValue = e.target.innerText;
    setUserSelectedId(idTextValue);
    props.onIdSelected(selectedId);
    setViewPossibleIds(false);
  };
  return (
    <div className={classes.idTypesContainer}>
      <ClickAwayListener onClickAway={hideList}>
        <button className={classes.idLabel} onClick={showList}>
          <span>{selectedId ? selectedId : userSelectedId}</span>
          <span>
            <img src="/images/arrowpointer.svg" alt="arrow button" />
          </span>
        </button>

        <Transition
          in={viewPossibleIds}
          nodeRef={idList}
          unmountOnExit
          timeout={300}
        >
          {(state) => {
            return (
              <ul
                className={`${classes.idTypesList}  ${
                  state === "entering"
                    ? classes.showList
                    : state === "exiting"
                    ? classes.hideList
                    : null
                }`}
                ref={idList}
              >
                <li>
                  <button
                    className={classes.idType}
                    onClick={submitSelection}
                    value={"運転免許証"}
                  >
                    運転免許証
                  </button>
                </li>
                <li>
                  <button
                    className={classes.idType}
                    value={"マイナンバーカード"}
                    onClick={submitSelection}
                  >
                    マイナンバーカード（個人番号カード）
                  </button>
                </li>
                <li>
                  <button
                    className={classes.idType}
                    value={"住民基本台帳カード"}
                    onClick={submitSelection}
                  >
                    {" "}
                    住民基本台帳カード ＋ 補助書類
                  </button>
                </li>
                <li>
                  <button
                    className={classes.idType}
                    disabled={deliveryMethod === "mail"}
                    value={"健康保険証"}
                    onClick={submitSelection}
                  >
                    健康保険証 ＋ 補助書類
                  </button>
                </li>
                <li>
                  <button
                    className={classes.idType}
                    value={"特別永住者証明書"}
                    onClick={submitSelection}
                  >
                    {" "}
                    特別永住者証明書
                  </button>
                </li>
                <li>
                  <button
                    className={classes.idType}
                    value={"在留カード"}
                    onClick={submitSelection}
                  >
                    在留カード
                  </button>
                </li>
                <li>
                  <button
                    className={classes.idType}
                    value={"しょうがい者手帳"}
                    onClick={submitSelection}
                  >
                    {" "}
                    しょうがい者手帳
                  </button>
                </li>
              </ul>
            );
          }}
        </Transition>
      </ClickAwayListener>
    </div>
  );
}
export default IdType;
