import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { orderActions } from "../../store/orderSlice";

function useSubmitEmail() {
  const alreadySubmittedEmail = useSelector(
    (state) => state.order.emailAddress
  );
  const [firstEmail, setFirstEmail] = useState({
    userEmail: alreadySubmittedEmail,
    setErrorText: (a) => {},
    setHasError: (a) => {},
    setInputIsValid: (a) => {},
  });
  const [verificationInput, setVerificationInput] = useState({
    userEmail: alreadySubmittedEmail,
    setErrorText: (a) => {},
    setHasError: (a) => {},
    setInputIsValid: (a) => {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const enteredEmail = firstEmail.userEmail ? firstEmail.userEmail : null;
    const verificationEmail = verificationInput.userEmail
      ? verificationInput.userEmail
      : null;

    if (enteredEmail && verificationEmail) {
      if (
        enteredEmail &&
        verificationEmail &&
        enteredEmail === verificationEmail
      ) {
        firstEmail.setInputIsValid(true);
        verificationInput.setInputIsValid(true);
        firstEmail.setHasError(false);
        verificationInput.setHasError(false);
        firstEmail.setErrorText(null);
        verificationInput.setErrorText(null);

        dispatch(orderActions.setEmailAddress(enteredEmail));
      } else {
        firstEmail.setInputIsValid(false);
        verificationInput.setInputIsValid(false);
        firstEmail.setHasError(true);
        verificationInput.setHasError(true);
        firstEmail.setErrorText("申し訳ありませんが、メールが一致しません。");
        verificationInput.setErrorText(
          "申し訳ありませんが、メールが一致しません。"
        );
        dispatch(orderActions.setEmailAddress(null));
      }
    } else {
      dispatch(orderActions.setEmailAddress(null));
    }
  }, [firstEmail, verificationInput, dispatch]);
  //
  useEffect(() => {
    if (alreadySubmittedEmail) {
      dispatch(orderActions.setEmailAddress(alreadySubmittedEmail));
      return;
    } else {
      return;
    }
  }, []);
  return function (data, setHasError, setErrorText, setInputIsValid) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;

    const inputName = data.name;
    const userEmail = data.enteredData;
    const cannotAcceptentry =
      userEmail.includes("@docomo.ne.jp") ||
      userEmail.includes("@ezweb.ne.jp") ||
      userEmail.includes("@softbank.ne.jp") ||
      userEmail.includes("@i.softbank.jp") ||
      userEmail.includes("@au.com") ||
      userEmail.includes(".au.com") ||
      userEmail.includes("sky.tkk.ne.jp") ||
      userEmail.includes("sky.tkc.ne.jp") ||
      userEmail.includes(".sky.dtg.ne.jp") ||
      userEmail.includes("sky.tu-ka.ne.jp") ||
      userEmail.includes(".ido.ne.jp") ||
      userEmail.includes(".ezweb.ne.jp") ||
      userEmail.includes(".biz.ezweb.ne.jp") ||
      userEmail.includes("@disney.ne.jp") ||
      userEmail.includes("@d.vodafone.ne.jp") ||
      userEmail.includes("@h.vodafone.ne.jp") ||
      userEmail.includes("@t.vodafone.ne.jp") ||
      userEmail.includes("@c.vodafone.ne.jp") ||
      userEmail.includes("@k.vodafone.ne.jp") ||
      userEmail.includes("@r.vodafone.ne.jp") ||
      userEmail.includes("@n.vodafone.ne.jp") ||
      userEmail.includes("@s.vodafone.ne.jp") ||
      userEmail.includes("@q.vodafone.ne.jp") ||
      userEmail.includes("@jp-k.ne.jp") ||
      userEmail.includes("@jp-t.ne.jp") ||
      userEmail.includes("@jp-q.ne.jp") ||
      userEmail.includes("@jp-s.ne.jp") ||
      userEmail.includes("@jp-n.ne.jp") ||
      userEmail.includes("@jp-c.ne.jp") ||
      userEmail.includes("@jp-h.ne.jp") ||
      userEmail.includes("@jp-d.ne.jp") ||
      userEmail.includes("@jp-r.ne.jp") ||
      userEmail.includes("@email.sky.cdp.ne.jp") ||
      userEmail.includes("@email.sky.kdp.ne.jp") ||
      userEmail.includes("@email.sky.tdp.ne.jp") ||
      userEmail.includes("@nttpnet.ne.jp") ||
      userEmail.includes(".nttpnet.ne.jp") ||
      userEmail.includes("@pdx.ne.jp") ||
      userEmail.includes(".pdx.ne.jp") ||
      userEmail.includes("@pipopa.ne.jp") ||
      userEmail.includes(".pipopa.ne.jp") ||
      userEmail.includes("@mozio.ne.jp") ||
      userEmail.includes(".mozio.ne.jp") ||
      userEmail.includes("@phone.ne.jp") ||
      userEmail.includes(".phone.ne.jp") ||
      userEmail.includes("@willcom.com") ||
      userEmail.includes("@emnet.ne.jp") ||
      userEmail.includes("@xxne.jp") ||
      userEmail.includes("@uqmobile.jp") ||
      userEmail.includes("@newad.jp") ||
      userEmail.includes("@15ne.jp") ||
      userEmail.includes("@ezweb.ne.jp") ||
      userEmail.includes("@ezweb.ne.jp");

    if (cannotAcceptentry) {
      setHasError(true);
      setErrorText("メールアドレスの形式が正しくありません");
      setInputIsValid(false);
      dispatch(orderActions.setEmailAddress(null));
      return;
    } else {
      if (
        inputName === "customer_email" &&
        userEmail.length > 256 &&
        (emailRegex.test(userEmail) || !emailRegex.test(userEmail))
      ) {
        setHasError(true);
        setErrorText("256文字以下で入力してください");
        setInputIsValid(false);
        dispatch(orderActions.setEmailAddress(null));
      } else if (
        inputName === "confirm_customer_email" &&
        userEmail.length > 256 &&
        (emailRegex.test(userEmail) || !emailRegex.test(userEmail))
      ) {
        setHasError(true);
        setErrorText("256文字以下で入力してください");
        setInputIsValid(false);
        dispatch(orderActions.setEmailAddress(null));
      } else if (
        inputName === "customer_email" &&
        userEmail.length < 256 &&
        emailRegex.test(userEmail)
      ) {
        setFirstEmail({
          userEmail: userEmail,
          setErrorText: setErrorText,
          setHasError: setHasError,
          setInputIsValid: setInputIsValid,
        });
      } else if (
        inputName === "confirm_customer_email" &&
        userEmail.length < 256 &&
        emailRegex.test(userEmail)
      ) {
        setVerificationInput({
          userEmail: userEmail,
          setErrorText: setErrorText,
          setHasError: setHasError,
          setInputIsValid: setInputIsValid,
        });
      } else if (
        (inputName === "confirm_customer_email" ||
          inputName === "customer_email") &&
        userEmail.length < 256 &&
        !emailRegex.test(userEmail)
      ) {
        setHasError(true);
        setErrorText("メールアドレスの形式が正しくありません");
        setInputIsValid(false);
        dispatch(orderActions.setEmailAddress(null));
      } else {
        return;
      }
    }
  };
}
export default useSubmitEmail;
