import classes from "./submissionError.module.css";

function SubmissionError(props) {
  // const state = props.state;

  return (
    <div
      className={`${classes.submissionError} ${
        props.state === "entering"
          ? classes.entering
          : props.state === "exiting"
          ? classes.exiting
          : null
      }`}
    >
      <p>
        申し訳ございませんが、データを送信できませんでした。詳細をご確認の上、再度お試しください。
      </p>
    </div>
  );
}
export default SubmissionError;
