import Ripple from "react-ripples";
import classes from "./reUploadButton.module.css";

function ReUploadButton(props) {
  return (
    <div className={classes.reUploadContainer}>
      <Ripple className={classes.reUploadLabelRipple}>
        <label htmlFor={props.elementId} className={classes.reUploadSection}>
          やりなおす
        </label>
      </Ripple>
    </div>
  );
}
export default ReUploadButton;
