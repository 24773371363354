function setBirthDate(state, action, enteredBirthDateInfo) {
  if (enteredBirthDateInfo) {
    state.birthYear = enteredBirthDateInfo.birthYear;
    state.birthMonth = enteredBirthDateInfo.birthMonth;
    state.birthDaySubmitted = enteredBirthDateInfo.bornDay;
    state.birthDay = enteredBirthDateInfo.birthDay;
  } else {
    state.birthYear = null;
    state.birthMonth = null;
    state.birthDaySubmitted = null;
    state.birthDay = null;
  }
}
export default setBirthDate;
