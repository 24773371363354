import { createSlice } from "@reduxjs/toolkit";
import defaultState from "./defaultData";
import setBirthDate from "./functions/setBirthDate";
import setBundle from "./functions/setBundle";
import setHuriganaNames from "./functions/setHuriganaNames";
import setImageAndDocs from "./functions/setIdImageAndDocs";
import setNormalNames from "./functions/setNormalNames";
import setPhoneAndSNumber from "./functions/setPhoneAndSNumber";
import setPlanOptions from "./functions/setPlanOptions";

let initialStoreStates = defaultState;

const storeSlice = createSlice({
  name: "store",
  initialState: initialStoreStates,
  reducers: {
    setApplicationType(state, action) {
      state.applicationType = action.payload;
    },
    setShopCode(state, action) {
      state.shopCode = action.payload;
    },
    setShopId(state, action) {
      state.shopID = action.payload;
    },
    setApplicationMethod(state, action) {
      state.mainApplicationType = action.payload;
    },
    setDeliveryMethod(state, action) {
      state.deliveryMethod = action.payload;
    },
    //order section
    setPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    setOptionalPhoneNumber(state, action) {
      state.tel2 = action.payload;
    },
    setSerialNumber(state, action) {
      state.serialNumber = action.payload;
    },
    setPhoneAndSNumberValidity(state, action) {
      setPhoneAndSNumber(state, action);
    },
    setTransferAndSubscriberName(state, action) {
      state.subscriberIsSameName = action.payload;
    },
    setOrderPlan(state, action) {
      state.contractPlan = action.payload;
    },
    setPlan(state, action) {
      state.monthlyFeeWithTax = action.payload;
    },
    setExpDate(state, action) {
      state.MNPExpDate = action.payload;
    },
    setSelectedPlanOptions(state, action) {
      setPlanOptions(state, action);
    },
    setSelectedBundle(state, action) {
      setBundle(state, action);
    },
    termsRead(state, actions) {
      state.termsRead = true;
    },
    setMNPOTAICCD(state, action) {
      state.MNPOTAICCD = action.payload;
    },
    setMNPOTA(state, action) {
      state.MNPOTA = action.payload;
    },
    setMNPReservationNumber(state, action) {
      state.MNPReservationNumber = action.payload;
    },
    setMNP_phoneNumber(state, action) {
      state.MNP_phoneNumber = action.payload;
    },
    //
    setImei(state, action) {
      state.Imei = action.payload;
    },
    setCustomerName(state, action) {
      setNormalNames(state, action);
    },
    setHuriganaNames(state, action) {
      setHuriganaNames(state, action);
    },
    setEmailAddress(state, action) {
      state.emailAddress = action.payload;
    },
    setEmailAddressTwo(state, action) {
      state.emailAddress2 = action.payload;
    },
    setGender(state, action) {
      state.gender = action.payload;
    },
    setCustomerAge(state, action) {
      let age = action.payload;
      state.customerAge = age;
    },
    setBirthDay(state, action) {
      let enteredBirthDateInfo = action.payload;
      setBirthDate(state, action, enteredBirthDateInfo);
    },
    //address actions
    setPostalCode(state, action) {
      state.postalCode = action.payload;
    },
    setOrderResponseNumber(state, action) {
      state.applicationAccountNumber = action.payload;
    },
    setPrefecture(state, action) {
      let payload = action.payload;
      state.prefecture = payload;
    },
    setCityTownVillage(state, action) {
      let payload = action.payload;
      state.cityTownVillage = payload;
    },
    setStreetAddress(state, action) {
      let payload = action.payload;
      state.streetAddress = payload;
    },
    setBuildingInfo(state, action) {
      let payload = action.payload;
      state.buildingDetails = payload;
    },
    setIdType(state, action) {
      let payload = action.payload;
      state.idType = payload;
    },
    setIdImageAndDocs(state, action) {
      setImageAndDocs(state, action);
    },
  },
});
export const orderActions = storeSlice.actions;
export default storeSlice.reducer;
